import React, { useContext } from 'react';
import { Button } from 'onsolve-ui-components';
import { FormattedMessage } from 'react-intl';
import { RedirectForm } from 'components/Base';
import { signInTranslations } from 'translations';
import { locationService } from 'services';
import { ClientContext } from 'providers';

const apiURL = '/api/v1/authenticate/external';

const ExternalSignInSection = () => {
    const { allowSp = false } = useContext(ClientContext);
    const returnUrl = locationService.queryStringParams.ReturnUrl;

    return allowSp ? (
        <div>
            <RedirectForm className="pt-3" to={apiURL} params={{ returnUrl }}>
                <Button role="button" size="lg" color="secondary" className="text-uppercase m-auto" type="submit">
                    <FormattedMessage {...signInTranslations.ng_signInWithYourOrg} />
                </Button>
            </RedirectForm>
        </div>
    ) : null;
};

export default ExternalSignInSection;
