import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Popover, PopoverBody } from 'onsolve-ui-components';
import { componentsTranslations } from 'translations';
import { isMobile } from 'common/utility';
import { useWindowSize } from 'common/hooks';
import PopoverItemList from './PopoverItemList';

import './PasswordPopover.scss';

const PasswordPopover = ({ value, ...props }) => {
    const isNarrowScreen = useWindowSize() < 768;

    return (
        <Popover
            placement={isMobile.any() || isNarrowScreen ? 'bottom' : 'left'}
            className="onsolve-password-field-popover"
            size="md"
            shouldFocus
            {...props}
        >
            <PopoverBody className="onsolve-password-field-popover__body">
                <div>
                    <FormattedMessage {...componentsTranslations.ng_components_pwdFieldHintTitle} />
                    <PopoverItemList items={value} />
                </div>
            </PopoverBody>
        </Popover>
    );
};

PasswordPopover.propTypes = {
    value: PropTypes.arrayOf(PropTypes.object),
};

PasswordPopover.defaultProps = {
    value: [],
};

export default PasswordPopover;
