export const debounce = (func, delay) => {
    let timeout;

    return function (...args) {
        const later = function () {
            timeout = null;
            func(args);
        };

        const callNow = !timeout;

        clearTimeout(timeout);
        timeout = setTimeout(later, delay);

        if (callNow) {
            func(args);
        }
    };
};
