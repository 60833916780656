import { defineMessages } from 'react-intl';

const accountConfirmationTranslations = defineMessages({
    ng_accountConfirmation_smsConfirmation_header: {
        id: 'identityServer.selfRegistration.accountConfirmation.smsConfirmation.header',
        defaultMessage: 'SMS Confirmation',
    },
    ng_accountConfirmation_smsConfirmation_label: {
        id: 'identityServer.selfRegistration.accountConfirmation.smsConfirmation.label',
        defaultMessage: 'Please check your phone, and enter the provided authentication code.',
    },
    ng_accountConfirmation_emailConfirmation_header: {
        id: 'identityServer.selfRegistration.accountConfirmation.emailConfirmation.header',
        defaultMessage: 'Email Confirmation',
    },
    ng_accountConfirmation_emailConfirmation_label: {
        id: 'identityServer.selfRegistration.accountConfirmation.emailConfirmation.label',
        defaultMessage: 'Please check your email, and enter the provided authentication code.',
    },
    ng_accountConfirmation_voiceConfirmation_header: {
        id: 'identityServer.selfRegistration.accountConfirmation.voiceConfirmation.header',
        defaultMessage: 'Voice Call Confirmation',
    },
    ng_accountConfirmation_voiceConfirmation_label: {
        id: 'identityServer.selfRegistration.accountConfirmation.voiceConfirmation.label',
        defaultMessage: 'Please check your phone, and enter the provided authentication code.',
    },
    ng_accountConfirmation_nextButton: {
        id: 'identityServer.common.button.next',
        defaultMessage: 'Next',
    },
    ng_accountConfirmation_recieveCode_link: {
        id: 'identityServer.selfRegistration.accountConfirmation.recieveCode',
        defaultMessage: 'I did not receive a code',
    },
    ng_accountVerification_secureRegistrationHeader: {
        id: 'identityServer.selfRegistration.accountVerification.ng_accountVerification_secureRegistrationHeader',
        defaultMessage: 'Secure Registration',
    },
    ng_accountVerification_secureRegistrationMessage: {
        id: 'identityServer.selfRegistration.accountVerification.secureRegistrationMessage',
        defaultMessage: 'To access registration you are required to enter the access PIN provided by {organization}.',
    },
});

export default accountConfirmationTranslations;
