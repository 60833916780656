import spaSettingsService from './api/spaSettingsService';
import { URL_REG_EXP } from '../common/constants/regexp';

let config = {
    mir3ApiBaseUrl: '',
    googleRecaptchaKey: '',
    selfRegistrationTemplates: [],
    namespaceApiBaseUrl: '',
    externalDomainName: '',
    rightToBeForgottenBaseUrl: '',
    featureFlags: {},
};

const settingsConfigurationService = {
    get: () => {
        return config;
    },

    set: async () => {
        const response = await spaSettingsService.getDefault();

        if (response && response.responseJson) {
            config = updateUrl(response.responseJson);
        }
    },
};

const updateUrl = (items) => {
    if (!items) {
        return null;
    }

    for (let item in items) {
        if (typeof items[item] === 'string' || items[item] instanceof String) {
            if (items[item].match(URL_REG_EXP) && items[item].slice(-1) !== '/') {
                items[item] = items[item] + '/';
            }
        }
    }

    return items;
};

export default settingsConfigurationService;
