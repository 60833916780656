import PropTypes from 'prop-types';

const colorConfigurationService = {
    setColorScheme: (colorScheme) => {
        if (colorScheme) {
            var root = document.querySelector(':root');

            if (colorScheme.backgroundColor) {
                root.style.setProperty('--data-background-color', colorScheme.backgroundColor);
            }
            if (colorScheme.backgroundColor) {
                root.style.setProperty('--data-primary-color', colorScheme.primaryColor);
            }
            if (colorScheme.backgroundColor) {
                root.style.setProperty('--data-secondary-color', colorScheme.secondaryColor);
            }
        }
    },
};

colorConfigurationService.propTypes = {
    colorScheme: PropTypes.object.isRequired,
};

export default colorConfigurationService;
