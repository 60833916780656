import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { sharedTranslations, signInTranslations } from 'translations';
import { ClientContext, LanguageContext } from 'providers';
import { FORGOT_TYPES } from 'common/constants/enums';

import './style.scss';

const ForgotCredentialsLinks = ({ onForgotCredentials }) => {
    const {
        extensions: { allowForgotUsername },
    } = useContext(ClientContext);

    const { language } = useContext(LanguageContext);

    return (
        <div className="onsolve-forgot-credentials-section">
            {language?.lcId !== 'fr_CA' && (
                <>
                    <FormattedMessage {...signInTranslations.ng_signIn_forgot} />
                    &nbsp;
                </>
            )}
            {allowForgotUsername && (
                <>
                    <button
                        type="button"
                        alt="forgotUsername"
                        onClick={onForgotCredentials(FORGOT_TYPES.FORGOT_USERNAME)}
                        className="onsolve-forgot-credentials-section__link btn btn-link p-0 border-0"
                        id="forgot-username-button"
                    >
                        <FormattedMessage {...sharedTranslations.ng_generic_username} />
                    </button>
                    &nbsp;
                    <FormattedMessage {...signInTranslations.ng_signIn_or} />
                    &nbsp;
                </>
            )}
            <button
                type="button"
                alt="forgotPassword"
                onClick={onForgotCredentials(FORGOT_TYPES.FORGOT_PASSWORD)}
                className="onsolve-forgot-credentials-section__link btn btn-link p-0 border-0"
                id="forgot-password-button"
            >
                <FormattedMessage {...sharedTranslations.ng_generic_password} />
            </button>
            {language?.lcId === 'fr_CA' && (
                <span className="ml-1">
                    <FormattedMessage {...signInTranslations.ng_signIn_forgot} />
                </span>
            )}
            ?
        </div>
    );
};

ForgotCredentialsLinks.propTypes = {
    onForgotCredentials: PropTypes.func,
};

export default ForgotCredentialsLinks;
