import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { CheckBoxCircleIcon, TimesCircleIcon } from 'onsolve-ui-components';

import './PopoverItemList.scss';

const PopoverItemList = ({ items }) => {
    const itemList = items
        .filter((x) => !!x)
        .map(({ message, valid }) => {
            const className = classNames({
                'onsolve-popover-item-list__item-striked': valid,
                'onsolve-popover-item-list__item': !valid,
            });
            const iconColor = classNames({
                'onsolve-popover-item-list__icon': valid,
                'onsolve-popover-item-list__icon-failed': !valid,
            });

            const Icon = valid ? CheckBoxCircleIcon : TimesCircleIcon;

            return (
                <div key={message.props.id}>
                    <Icon className={iconColor} />
                    <span className={className}>{message}</span>
                </div>
            );
        });

    return <div className="onsolve-password-field-popover__body-list">{itemList}</div>;
};

PopoverItemList.propTypes = {
    items: PropTypes.object,
    message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    valid: PropTypes.bool,
};

export default PopoverItemList;
