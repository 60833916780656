import { defineMessages } from 'react-intl';

const coreUnAuthTranslations = defineMessages({
    ng_forgotCredentials_ResetPassword: {
        id: 'identityServer.forgotCredentials.resetPassword',
        defaultMessage: 'Reset your password',
    },
    ng_forgotCredentials_ResetPassword_sub_title: {
        id: 'identityServer.forgotCredentials.resetPassword.subTitle',
        defaultMessage: 'Enter and confirm your new password below. Both entries are case-sensitive.',
    },
    ng_forgotCredentials_ResetPassword_sub_title_success: {
        id: 'identityServer.forgotCredentials.resetPassword.subTitlesucces',
        defaultMessage: ' Your password has been reset successfully. Please continue to sign in.',
    },
    ng_forgotCredentials_ResetPassword_short: {
        id: 'identityServer.forgotCredentials.resetPassword.short',
        defaultMessage: 'Reset password',
    },
    ng_forgotCredentials_ResetPassword_SelfReg_subtitle: {
        id: 'identityServer.selfRegistration.forgotCredentials.resetPassword.subTitle',
        defaultMessage: 'Enter and confirm your new password below.\nBoth entries are case-sensitive.',
    },
    ng_forgotCredentials_savePassword: {
        id: 'identityServer.forgotCredentials.password.save',
        defaultMessage: 'Save new password',
    },
    ng_forgotCredentials_enterPassword: {
        id: 'identityServer.forgotCredentials.password.enter',
        defaultMessage: 'Enter your new password',
    },
    ng_forgotCredentials_enterPassword_short: {
        id: 'identityServer.forgotCredentials.password.short',
        defaultMessage: 'Enter new password',
    },
    ng_forgotCredentials_confirmPassword: {
        id: 'identityServer.forgotCredentials.password.confirm',
        defaultMessage: 'Confirm password',
    },
    ng_forgotCredentials_enterConfirmPassword: {
        id: 'identityServer.forgotCredentials.password.again',
        defaultMessage: 'Enter password again to confirm',
    },
    ng_forgotCredentials_goto_signIn: {
        id: 'identityServer.forgotCredentials.goToSignIn',
        defaultMessage: 'Go To Sign In',
    },
    ng_forgotCredentials_resetPassword_success: {
        id: 'identityServer.forgotCredentials.resetPassword.success',
        defaultMessage: 'Your password has been reset successfully!',
    },
    ng_forgotCredentials_resetPassword_codeRed_success: {
        id: 'identityServer.forgotCredentials.resetPassword.codeRed.success',
        defaultMessage: 'Your password has been reset successfully! Please go back to the login page to sign in.',
    },
    ng_core_termsAndConditionsOfUse: {
        id: 'identityServer.common.termsAndConditionsOfUse',
        defaultMessage: 'Terms and Conditions of Use:',
    },
    ng_forgotCredentials_forgotCredentialsOr: {
        id: 'identityServer.selfRegistration.forgotCredentials.forgotOr',
        defaultMessage: 'Forgot {0} or {1}?',
    },

    ng_common_success: {
        id: 'identityServer.common.success',
        defaultMessage: 'Success',
    },
    ng_forgotCredentials_tokenVerification: {
        id: 'identityServer.selfRegistration.forgotCredentials.tokenVerification',
        defaultMessage: 'Token Verification',
    },
    ng_forgotCredentials_unableToReset: {
        id: 'identityServer.selfRegistration.forgotCredentials.unableToReset',
        defaultMessage: 'Unable to reset your password',
    },
    ng_forgotCredentials_invalidOrExpiredToken: {
        id: 'identityServer.selfRegistration.forgotCredentials.invalidOrExpiredToken',
        defaultMessage: 'The Reset Password link has expired or is invalid. Please try again.',
    },
    ng_forgotCredentials_tollFee: {
        id: 'identityServer.selfRegistration.forgotCredentials.tollFee',
        defaultMessage: 'Toll Free: ',
    },
    ng_forgotCredentials_usTollNumber: {
        id: 'identityServer.selfRegistration.forgotCredentials.tollNumber.US',
        defaultMessage: 'U.S. Toll Number: ',
    },
    ng_forgotCredentials_ukTollFreeNumber: {
        id: 'identityServer.selfRegistration.forgotCredentials.tollFreeNumber.UK',
        defaultMessage: 'UK Toll-Free Number: ',
    },
    ng_forgotCredentials_email: {
        id: 'identityServer.selfRegistration.forgotCredentials.email',
        defaultMessage: 'Email: ',
    },
    ng_forgotCredentials_toInitiateAnAlert: {
        id: 'identityServer.selfRegistration.forgotCredentials.toInitiateAnAlert',
        defaultMessage: 'To Initiate an Alert: ',
    },
    ng_forgotCredentials_universalInternationalFreephoneNumber: {
        id: 'identityServer.selfRegistration.forgotCredentials.universalInternationalFreephoneNumber',
        defaultMessage: 'Universal International Freephone Number: ',
    },
    ng_forgotCredentials_securityQuestions: {
        id: 'identityServer.selfRegistration.forgotCredentials.securityQuestions',
        defaultMessage: 'Security Questions',
    },
    ng_forgotCredentials_answerSecurityQuestions: {
        id: 'identityServer.selfRegistration.forgotCredentials.answerSecurityQuestions',
        defaultMessage: 'Please answer the following security question to continue.',
    },
});

export default coreUnAuthTranslations;
