import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const RedirectForm = ({ children, to, params, className = '' }) => {
    const fields = useMemo(() => {
        const names = Object.keys(params);

        return names.map((name) => {
            return <input key={name} type="hidden" value={params[name]} name={name} />;
        });
    }, [params]);

    return (
        <form className={className} tabIndex="-1" method="GET" action={to}>
            {fields}
            {children}
        </form>
    );
};

RedirectForm.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
    params: PropTypes.object,
    to: PropTypes.string,
};

export default RedirectForm;
