import { defineMessages } from 'react-intl';

const multiFactorAuthenticationTranslations = defineMessages({
    ng_MFA_authenticationMethod: {
        id: 'identityServer.MFA.authenticationMethod',
        defaultMessage: 'Authentication Method',
    },
    ng_MFA_selectAuthenticationMethod: {
        id: 'identityServer.MFA.selectAuthenticationMethod',
        defaultMessage: 'Select authentication method',
    },
    ng_MFA_header: {
        id: 'identityServer.MFA.header',
        defaultMessage: 'Multi-Factor Authentication',
    },
    ng_MFA_description: {
        id: 'identityServer.MFA.description',
        defaultMessage:
            'Multi Factor Authentication is required for system access.  Select authentication method and complete registration.',
    },
    ng_MFA_devices_SMS: {
        id: 'identityServer.MFA.devices_SMS',
        defaultMessage: 'SMS ',
    },
    ng_MFA_devices_MSAuth: {
        id: 'identityServer.MFA.devices_MSAuth',
        defaultMessage: 'Microsoft Authenticator',
    },
    ng_MFA_smsNumber: {
        id: 'identityServer.MFA.smsNumber',
        defaultMessage: 'SMS number',
    },
    ng_MFA_selectSmsNumber: {
        id: 'identityServer.MFA.selectSmsNumber',
        defaultMessage: 'Select SMS number',
    },
    ng_MFA_enterAuthCodeDescription: {
        id: 'identityServer.MFA.enterAuthCodeDescription',
        defaultMessage: 'Please enter the Authentication code sent to',
    },
    ng_MFA_authenticationCode: {
        id: 'identityServer.MFA.authenticationCode',
        defaultMessage: 'Authentication Code',
    },
    ng_MFA_enterAuthCodePlaceholder: {
        id: 'identityServer.MFA.enterAuthCodePlaceholder',
        defaultMessage: 'Enter authentication code',
    },
    ng_MFA_wrongAuthCode: {
        id: 'identityServer.MFA.wrongAuthCode',
        defaultMessage: 'Authentication code is incorrect',
    },
    ng_MFA_resendCode: {
        id: 'identityServer.MFA.resendCode',
        defaultMessage: 'Resend code',
    },
    ng_MFA_authenticate: {
        id: 'identityServer.MFA.authenticate',
        defaultMessage: 'Authenticate',
    },
    ng_MFA_setupSuccessMessage: {
        id: 'identityServer.MFA.setupSuccessMessage',
        defaultMessage:
            'You have completed setup of Multi-Factor Authentication. You may edit this within your user settings.',
    },
    ng_MFA_enterVerificationCodeDescription: {
        id: 'identityServer.MFA.enterVerificationCodeDescription',
        defaultMessage: 'Enter the verification code sent to {number} to access your account.',
    },
    ng_MFA_clickToResendPin: {
        id: 'identityServer.MFA.clickToResendPin',
        defaultMessage: 'Click here to resend your PIN.',
    },
    ng_mfa_smsVerificationNeeded: {
        id: 'identityServer.MFA.smsVerificationNeeded',
        defaultMessage: 'SMS Verification Needed!',
    },
    ng_MFA_noSmsDevicesConfigured: {
        id: 'identityServer.MFA.noSmsDevicesConfigured',
        defaultMessage:
            'Your account does not contain any SMS devices. Please contact your system administrator for futher assistance.',
    },
});

export default multiFactorAuthenticationTranslations;
