import mir3Service from './api/mir3Service';

import { ae } from 'assets/langs/ae';
import { br } from 'assets/langs/br';
import { ca } from 'assets/langs/ca';
import { cz } from 'assets/langs/cz';
import { de } from 'assets/langs/de';
import { dk } from 'assets/langs/dk';
import { es } from 'assets/langs/es';
import { fi } from 'assets/langs/fi';
import { fr } from 'assets/langs/fr';
import { gr } from 'assets/langs/gr';
import { hu } from 'assets/langs/hu';
import { inn } from 'assets/langs/in';
import { it } from 'assets/langs/it';
import { jp } from 'assets/langs/jp';
import { kr } from 'assets/langs/kr';
import { mx } from 'assets/langs/mx';
import { nl } from 'assets/langs/nl';
import { no } from 'assets/langs/no';
import { pl } from 'assets/langs/pl';
import { pt } from 'assets/langs/pt';
import { ro } from 'assets/langs/ro';
import { ru } from 'assets/langs/ru';
import { se } from 'assets/langs/se';
import { simplified } from 'assets/langs/simplified';
import { th } from 'assets/langs/th';
import { tr } from 'assets/langs/tr';
import { traditional } from 'assets/langs/traditional';
import { us } from 'assets/langs/us';
import { locales } from 'assets/langs/locales';

const LOCAL_REGION_DATA = {
    ae,
    br,
    ca,
    cz,
    de,
    dk,
    es,
    fi,
    fr,
    gr,
    hu,
    in: inn,
    it,
    jp,
    kr,
    mx,
    nl,
    no,
    pl,
    pt,
    ro,
    ru,
    se,
    cn: simplified,
    th,
    tr,
    tw: traditional,
    us,
};

const LOCAL_LOCALES = locales;

const localeService = {
    locales: [],

    lang: {},

    init(language) {
        return this.getTranslations(language);
    },

    async getLocales() {
        this.locales = LOCAL_LOCALES;

        return this.locales;
    },

    async getMir3Locales() {
        const result = await mir3Service.getLocales();

        if (result) {
            this.locales = result.responseJson;
        }

        return result;
    },

    getLocaleByCode(code) {
        return this.locales.find(
            (locale) => locale.isoCode === code || locale.cultureLanguageCode === code || locale.lcId === code
        );
    },

    async getTranslations(language) {
        const translations = LOCAL_REGION_DATA[language.cultureRegionCode.toLowerCase()];

        this.lang = {
            ...language,
            translations: translations,
        };

        return this.lang;
    },

    async getMir3Translations(language) {
        const response = await mir3Service.getTranslations(language);

        this.lang = {
            ...language,
            translations: response.responseJson.data,
        };

        return this.lang;
    },
};

export default localeService;
