import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CountryCodeDropdown from './CountryCodeDropdown';

const CountryCodeField = ({ setFieldValue, name, initialValue, value, countryCodeList, ...props }) => {
    const [searchValue, setSearchValue] = useState(initialValue);
    const [selectedValue, setSelectedValue] = useState(initialValue);

    useEffect(() => {
        setSearchValue(value);
        setSelectedValue(value);
    }, [value]);

    const handleSelect = (newValue) => {
        setFieldValue(name, newValue);
        setSearchValue(newValue);
        setSelectedValue(newValue);
    };

    const handleChange = (newValue) => {
        if (newValue != null) {
            setSearchValue(newValue);
            setSelectedValue(selectedValue);
        } else {
            setSearchValue(selectedValue);
            setSelectedValue(selectedValue);
        }
    };

    return (
        <CountryCodeDropdown
            countryCodeList={countryCodeList}
            value={searchValue}
            onChange={handleChange}
            onSelect={handleSelect}
            {...props}
        />
    );
};

CountryCodeField.propTypes = {
    countryCodeList: PropTypes.arrayOf(
        PropTypes.shape({
            countryCode: PropTypes.string,
            countryName: PropTypes.string,
            id: PropTypes.string,
        })
    ),
    initialValue: PropTypes.string,
    name: PropTypes.object,
    setFieldValue: PropTypes.func,
    value: PropTypes.object,
};

export default CountryCodeField;
