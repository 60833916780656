export const defaultMasks = {
    numeric: /^\d+$/,
    alpha: /^[a-zA-Z ]*$/,
    alphanumeric: /^[a-zA-Z0-9 ]*$/,
    default: /.*/,
    password: /^[a-zA-Z\d!@#$]*$/,
};

export const insert = (str, start, end, value) => {
    return str.substr(0, start) + value + str.substr(end);
};

const minValidator = (min, value) => {
    if (typeof min === 'number') {
        return parseFloat(value) >= parseFloat(min);
    }
    return true;
};

const maxValidator = (max, value) => {
    if (typeof max === 'number') {
        return parseFloat(value) <= parseFloat(max);
    }
    return true;
};

const patternValidator = (pattern, value) => {
    if (typeof pattern === 'string') {
        const regex = defaultMasks[pattern] ? defaultMasks[pattern] : new RegExp(pattern);

        return regex.test(value);
    }
    if (pattern instanceof RegExp) {
        return pattern.test(value);
    }
    return true;
};

export const getValue = (event, input) => {
    const { value, selectionStart, selectionEnd } = input;

    let charCode = event.charCode || event.keyCode || event.which;

    charCode = String.fromCharCode(charCode);

    return insert(value, selectionStart, selectionEnd, charCode);
};

export const validators = {
    min: minValidator,
    max: maxValidator,
    pattern: patternValidator,
};
