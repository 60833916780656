import { defineMessages } from 'react-intl';

const alreadyRegistredTranslations = defineMessages({
    ng_alreadyRegistred_title: {
        id: 'identityServer.selfRegistration.alreadyRegistred.title',
        defaultMessage: 'Already Registered',
    },
    ng_alreadyRegistred_text: {
        id: 'identityServer.selfRegistration.alreadyRegistred.text',
        defaultMessage:
            'Looks like you already got an account with us. The {deviceType} provided is already registered to an account. Please sign in, or you may recover your username and password through the sign in page. ',
    },
    ng_alreadyRegistred_close_button: {
        id: 'identityServer.selfRegistration.alreadyRegistred.button.close',
        defaultMessage: 'Close',
    },
});

export default alreadyRegistredTranslations;
