import React from 'react';

const ServerErrorImage = () => (
    <svg width="514" height="196">
        <g fill="none" fillRule="evenodd">
            <path stroke="#979797" d="M-1916.5-1145.5h4554v2194h-4554z" />
            <path d="M-383-157H897V843H-383z" />
            <path d="M41 195h400M513 195h-60M1 195h20" stroke="#D8D8D8" strokeLinecap="square" />
            <path
                d="M278.3 64.346c-.32-.66-.493-1.22-.522-1.682-.028-.461.078-.977.32-1.548.19-.444.52-.828.994-1.15a8.063 8.063 0 0 1 1.524-.814c.931-.428 2.13-1.005 3.598-1.729 1.468-.724 3.04-1.648 4.714-2.772a42.655 42.655 0 0 0 4.988-3.946 27.115 27.115 0 0 0 4.38-5.16 2.112 2.112 0 0 1-.659-.167l-.618-.263c-3.299-1.4-5.515-3.632-6.647-6.696-1.133-3.063-.972-6.308.482-9.733 1.265-2.982 3.373-5.082 6.323-6.3s6.138-1.1 9.564.354c1.903.808 3.436 1.87 4.599 3.187a11.161 11.161 0 0 1 2.431 4.457c.458 1.655.598 3.398.419 5.231-.18 1.833-.673 3.702-1.48 5.605-1.212 2.854-2.868 5.651-4.966 8.391-2.1 2.74-4.565 5.287-7.399 7.64-2.833 2.354-5.965 4.487-9.397 6.4a57.114 57.114 0 0 1-10.965 4.666l-1.683-3.971zm-37.505-2.632c.187-1.782.69-3.427 1.506-4.935.817-1.508 1.852-2.785 3.105-3.83 1.254-1.047 2.705-1.83 4.353-2.35a12.565 12.565 0 0 1 5.145-.498c1.782.187 3.425.706 4.93 1.557a13.26 13.26 0 0 1 3.82 3.208 13.26 13.26 0 0 1 2.344 4.404c.52 1.648.685 3.363.498 5.145-.194 1.85-.715 3.511-1.563 4.981a13.435 13.435 0 0 1-3.202 3.77 12.561 12.561 0 0 1-4.399 2.291c-1.644.486-3.358.635-5.14.447-1.782-.187-3.427-.689-4.934-1.506-1.508-.816-2.767-1.867-3.775-3.15a13.143 13.143 0 0 1-2.246-4.342c-.489-1.611-.636-3.341-.442-5.192z"
                fillOpacity=".12"
                fill="#3777BC"
            />
            <path
                d="M224.155 46.684a37.213 37.213 0 0 1-5.051 1.683c-1.638.412-3.31.616-5.017.614a23.594 23.594 0 0 1 8.886 5.166l18.978 15.486-9.7 11.884-18.978-15.487a49.11 49.11 0 0 1-3.928-3.709c-1.193-1.262-2.174-2.58-2.945-3.955.683 3.06.728 6.467.133 10.22l-3.815 24.089-15.515-2.458 3.794-23.957c.277-1.746.656-3.43 1.138-5.054a23.429 23.429 0 0 1 1.864-4.536 17.594 17.594 0 0 1-3.937 2.731 88.584 88.584 0 0 1-4.664 2.214l-22.683 8.754-5.684-14.32 22.814-8.733c3.635-1.393 6.984-2.115 10.048-2.167-2.985-1.01-5.94-2.774-8.866-5.296l-18.978-15.486 9.7-11.884 18.957 15.618a50.497 50.497 0 0 1 3.873 3.633 23.698 23.698 0 0 1 3.021 3.9c-.683-3.06-.735-6.424-.154-10.09l3.836-24.219 15.515 2.458-3.794 23.957a40.48 40.48 0 0 1-1.158 5.185 18.911 18.911 0 0 1-1.996 4.515 21.294 21.294 0 0 1 4.069-2.71 88.584 88.584 0 0 1 4.663-2.214l22.683-8.754 5.684 14.32-22.793 8.602z"
                fillOpacity=".12"
                fill="#3777BC"
            />
            <path
                d="M220.155 46.684a37.213 37.213 0 0 1-5.051 1.683c-1.638.412-3.31.616-5.017.614a23.594 23.594 0 0 1 8.886 5.166l18.978 15.486-9.7 11.884-18.978-15.487a49.11 49.11 0 0 1-3.928-3.709c-1.193-1.262-2.174-2.58-2.945-3.955.683 3.06.728 6.467.133 10.22l-3.815 24.089-15.515-2.458 3.794-23.957c.277-1.746.656-3.43 1.138-5.054a23.429 23.429 0 0 1 1.864-4.536 17.594 17.594 0 0 1-3.937 2.731 88.584 88.584 0 0 1-4.664 2.214l-22.683 8.754-5.684-14.32 22.814-8.733c3.635-1.393 6.984-2.115 10.048-2.167-2.985-1.01-5.94-2.774-8.866-5.296l-18.978-15.486 9.7-11.884 18.957 15.618a50.497 50.497 0 0 1 3.873 3.633 23.698 23.698 0 0 1 3.021 3.9c-.683-3.06-.735-6.424-.154-10.09l3.836-24.219 15.515 2.458-3.794 23.957a40.48 40.48 0 0 1-1.158 5.185 18.911 18.911 0 0 1-1.996 4.515 21.294 21.294 0 0 1 4.069-2.71 88.584 88.584 0 0 1 4.663-2.214l22.683-8.754 5.684 14.32-22.793 8.602z"
                stroke="#D8D8D8"
                fill="#FFF"
            />
            <path
                d="M248.145 109.854c-.939-3.503-2.683-5.762-5.233-6.778-2.55-1.015-5.507-1.072-8.874-.17a58.324 58.324 0 0 0-7.638 2.628 195.31 195.31 0 0 0-7.447 3.337 189.787 189.787 0 0 1-7.489 3.347 60.502 60.502 0 0 1-7.844 2.683c-4.195 1.124-8.181 1.537-11.959 1.238-3.777-.3-7.233-1.385-10.367-3.256-3.134-1.872-5.895-4.559-8.283-8.061s-4.282-7.866-5.682-13.093l-1.185-4.42 9.025-2.418c.717-.193 1.393-.21 2.027-.052a4.02 4.02 0 0 1 1.638.813c.457.384.836.804 1.136 1.26.3.456.501.878.606 1.268l.2.75c1.044 3.892 3.051 6.528 6.024 7.907 2.972 1.378 6.556 1.506 10.75.382a82.944 82.944 0 0 0 8.613-2.8 222.402 222.402 0 0 0 7.834-3.216 294.028 294.028 0 0 1 7.462-3.117 66.602 66.602 0 0 1 7.577-2.522c2.042-.547 4.08-.78 6.113-.7 2.033.082 3.971.471 5.815 1.17a18.008 18.008 0 0 1 5.099 2.968c1.556 1.282 2.906 2.842 4.05 4.68.015-2.149.39-4.171 1.125-6.066a18.761 18.761 0 0 1 2.973-5.132 18.377 18.377 0 0 1 4.451-3.92 20.368 20.368 0 0 1 5.644-2.45 66.602 66.602 0 0 1 7.823-1.605c2.62-.374 5.294-.718 8.02-1.032 2.726-.313 5.524-.69 8.393-1.131a82.944 82.944 0 0 0 8.858-1.882c4.25-1.139 7.317-3.048 9.202-5.728 1.885-2.68 2.306-5.967 1.263-9.859l-.201-.75c-.104-.39-.14-.857-.11-1.402.032-.544.15-1.097.355-1.659a4.148 4.148 0 0 1 .97-1.511c.443-.447 1.023-.766 1.74-.958l9.107-2.44 1.185 4.42c1.4 5.226 1.942 9.953 1.625 14.18-.316 4.227-1.378 7.938-3.184 11.133-1.806 3.195-4.27 5.867-7.392 8.015-3.122 2.147-6.78 3.783-10.975 4.907a60.502 60.502 0 0 1-8.135 1.599c-2.718.34-5.438.623-8.159.845-2.72.223-5.413.497-8.076.823a57.405 57.405 0 0 0-7.887 1.532c-3.367.903-5.9 2.43-7.6 4.585-1.701 2.154-2.082 4.982-1.144 8.485l-11.84 3.173z"
                fillOpacity=".12"
                fill="#3777BC"
            />
            <path
                d="M248.145 105.854c-.939-3.503-2.683-5.762-5.233-6.778-2.55-1.015-5.507-1.072-8.874-.17a58.324 58.324 0 0 0-7.638 2.628 195.31 195.31 0 0 0-7.447 3.337 189.787 189.787 0 0 1-7.489 3.347 60.502 60.502 0 0 1-7.844 2.683c-4.195 1.124-8.181 1.537-11.959 1.238-3.777-.3-7.233-1.385-10.367-3.256-3.134-1.872-5.895-4.559-8.283-8.061s-4.282-7.866-5.682-13.093l-1.185-4.42 9.025-2.418c.717-.193 1.393-.21 2.027-.052a4.02 4.02 0 0 1 1.638.813c.457.384.836.804 1.136 1.26.3.456.501.878.606 1.268l.2.75c1.044 3.892 3.051 6.528 6.024 7.907 2.972 1.378 6.556 1.506 10.75.382a82.944 82.944 0 0 0 8.613-2.8 222.402 222.402 0 0 0 7.834-3.216 294.028 294.028 0 0 1 7.462-3.117 66.602 66.602 0 0 1 7.577-2.522c2.042-.547 4.08-.78 6.113-.7 2.033.082 3.971.471 5.815 1.17a18.008 18.008 0 0 1 5.099 2.968c1.556 1.282 2.906 2.842 4.05 4.68.015-2.149.39-4.171 1.125-6.066a18.761 18.761 0 0 1 2.973-5.132 18.377 18.377 0 0 1 4.451-3.92 20.368 20.368 0 0 1 5.644-2.45 66.602 66.602 0 0 1 7.823-1.605c2.62-.374 5.294-.718 8.02-1.032 2.726-.313 5.524-.69 8.393-1.131a82.944 82.944 0 0 0 8.858-1.882c4.25-1.139 7.317-3.048 9.202-5.728 1.885-2.68 2.306-5.967 1.263-9.859l-.201-.75c-.104-.39-.14-.857-.11-1.402.032-.544.15-1.097.355-1.659a4.148 4.148 0 0 1 .97-1.511c.443-.447 1.023-.766 1.74-.958l9.107-2.44 1.185 4.42c1.4 5.226 1.942 9.953 1.625 14.18-.316 4.227-1.378 7.938-3.184 11.133-1.806 3.195-4.27 5.867-7.392 8.015-3.122 2.147-6.78 3.783-10.975 4.907a60.502 60.502 0 0 1-8.135 1.599c-2.718.34-5.438.623-8.159.845-2.72.223-5.413.497-8.076.823a57.405 57.405 0 0 0-7.887 1.532c-3.367.903-5.9 2.43-7.6 4.585-1.701 2.154-2.082 4.982-1.144 8.485l-11.84 3.173z"
                stroke="#D8D8D8"
                fill="#FFF"
            />
            <path
                d="M227.948 188.659c-1.418 2.088-3.295 3.56-5.633 4.419-2.338.858-4.603 1.162-6.793.913l-8.848-1.01 71.758-101.406c1.321-1.903 3.022-3.286 5.102-4.15 2.08-.862 4.356-1.153 6.828-.871l8.931 1.018-71.345 101.087z"
                fillOpacity=".12"
                fill="#3777BC"
            />
            <path
                d="M223.97 189.025c-1.417 2.087-3.295 3.56-5.633 4.419-2.338.858-4.602 1.162-6.793.912l-8.847-1.009 71.757-101.406c1.322-1.903 3.023-3.286 5.103-4.15 2.08-.863 4.356-1.153 6.827-.871l8.932 1.018-71.345 101.087z"
                stroke="#D8D8D8"
                fill="#FFF"
            />
            <path
                d="M318.066 188.114l-4.051 7.62-76.814-.378 7.13-13.411a6.216 6.216 0 0 1 2.257-2.417c.964-.595 2.175-.863 3.633-.805l35.06.364c2.994.094 6.08.561 9.257 1.404-2.476-2.163-4.589-4.46-6.34-6.89l-19.948-28.786c-.837-1.227-1.285-2.394-1.344-3.5-.059-1.107.182-2.168.722-3.184l7.13-13.41 43.308 63.393z"
                fillOpacity=".12"
                fill="#3777BC"
            />
            <path
                d="M314.066 187.114l-4.051 7.62-76.814-.378 7.13-13.411a6.216 6.216 0 0 1 2.257-2.417c.964-.595 2.175-.863 3.633-.805l35.06.364c2.994.094 6.08.561 9.257 1.404-2.476-2.163-4.589-4.46-6.34-6.89l-19.948-28.786c-.837-1.227-1.285-2.394-1.344-3.5-.059-1.107.182-2.168.722-3.184l7.13-13.41 43.308 63.393z"
                stroke="#D8D8D8"
                fill="#FFF"
            />
            <path
                d="M199.41 183.97l-8.336-2.234-16.911-74.93 14.671 3.93a6.216 6.216 0 0 1 2.862 1.656c.797.805 1.331 1.925 1.602 3.358l7.532 34.244c.583 2.938.821 6.05.715 9.335 1.55-2.898 3.313-5.474 5.287-7.727l23.561-25.912c1.007-1.091 2.043-1.79 3.108-2.097 1.065-.306 2.153-.31 3.265-.013l14.67 3.931-52.026 56.458z"
                fillOpacity=".12"
                fill="#3777BC"
            />
            <path
                d="M196.41 183.97l-8.336-2.234-16.911-74.93 14.671 3.93a6.216 6.216 0 0 1 2.862 1.656c.797.805 1.331 1.925 1.602 3.358l7.532 34.244c.583 2.938.821 6.05.715 9.335 1.55-2.898 3.313-5.474 5.287-7.727l23.561-25.912c1.007-1.091 2.043-1.79 3.108-2.097 1.065-.306 2.153-.31 3.265-.013l14.67 3.931-52.026 56.458z"
                stroke="#D8D8D8"
                fill="#FFF"
            />
            <path
                d="M220.3 70.292l8.4 6.416M226.333 73.3l3.334 2.4"
                stroke="#C8C8C8"
                strokeWidth=".5"
                strokeLinecap="square"
            />
            <g fillRule="nonzero" stroke="#C8C8C8" strokeLinecap="square" strokeWidth=".5">
                <path d="M196.227 77.809l-1.078 10.384M197.182 84.842l-.42 3.73" />
            </g>
            <g fillRule="nonzero" stroke="#C8C8C8" strokeLinecap="square" strokeWidth=".5">
                <path d="M182.592 105.713l9.685 3.898M189.616 106.733l3.47 1.432" />
            </g>
            <g fillRule="nonzero" stroke="#C8C8C8" strokeLinecap="square" strokeWidth=".5">
                <path d="M234.63 137.091l6.7-8.006M237.77 130.726l2.435-2.858" />
            </g>
            <g fillRule="nonzero" stroke="#C8C8C8" strokeLinecap="square" strokeWidth=".5">
                <path d="M268.63 120.091l6.7-8.006M271.77 113.726l2.435-2.858" />
            </g>
            <g fillRule="nonzero" stroke="#C8C8C8" strokeLinecap="square" strokeWidth=".5">
                <path d="M296.316 190.869l10.44-.014M303.211 189.184l3.754.028" />
            </g>
            <path
                d="M275.3 65.346c-.32-.66-.493-1.22-.522-1.682-.028-.461.078-.977.32-1.548.19-.444.52-.828.994-1.15a8.063 8.063 0 0 1 1.524-.814c.931-.428 2.13-1.005 3.598-1.729 1.468-.724 3.04-1.648 4.714-2.772a42.655 42.655 0 0 0 4.988-3.946 27.115 27.115 0 0 0 4.38-5.16 2.112 2.112 0 0 1-.659-.167l-.618-.263c-3.299-1.4-5.515-3.632-6.647-6.696-1.133-3.063-.972-6.308.482-9.733 1.265-2.982 3.373-5.082 6.323-6.3s6.138-1.1 9.564.354c1.903.808 3.436 1.87 4.599 3.187a11.161 11.161 0 0 1 2.431 4.457c.458 1.655.598 3.398.419 5.231-.18 1.833-.673 3.702-1.48 5.605-1.212 2.854-2.868 5.651-4.966 8.391-2.1 2.74-4.565 5.287-7.399 7.64-2.833 2.354-5.965 4.487-9.397 6.4a57.114 57.114 0 0 1-10.965 4.666l-1.683-3.971zm-37.505-2.632c.187-1.782.69-3.427 1.506-4.935.817-1.508 1.852-2.785 3.105-3.83 1.254-1.047 2.705-1.83 4.353-2.35a12.565 12.565 0 0 1 5.145-.498c1.782.187 3.425.706 4.93 1.557a13.26 13.26 0 0 1 3.82 3.208 13.26 13.26 0 0 1 2.344 4.404c.52 1.648.685 3.363.498 5.145-.194 1.85-.715 3.511-1.563 4.981a13.435 13.435 0 0 1-3.202 3.77 12.561 12.561 0 0 1-4.399 2.291c-1.644.486-3.358.635-5.14.447-1.782-.187-3.427-.689-4.934-1.506-1.508-.816-2.767-1.867-3.775-3.15a13.143 13.143 0 0 1-2.246-4.342c-.489-1.611-.636-3.341-.442-5.192z"
                stroke="#D8D8D8"
                fill="#FFF"
            />
            <g fillRule="nonzero" stroke="#C8C8C8" strokeLinecap="square" strokeWidth=".5">
                <path d="M308.244 40.831l-5.543 9M303.69 45.802l-2.253 3.435" />
            </g>
            <g fillRule="nonzero" stroke="#C8C8C8" strokeLinecap="square" strokeWidth=".5">
                <path d="M250.386 76.043l9.188-5.225M255.512 71.665l3.512-2.131" />
            </g>
        </g>
    </svg>
);

export default ServerErrorImage;
