import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { GlobusIcon } from 'onsolve-ui-components';

import LanguageList from './LanguageList';

const LanguageModal = ({ isOpen, toggleModal, currentLanguage, afterLanguageChange }) => {
    const handleLanguageChange = (event, language) => {
        event.preventDefault();
        afterLanguageChange(language);
    };

    return (
        <Modal
            isOpen={isOpen}
            className={`onsolve-language-wrapper onsolve-language-modal ${currentLanguage.direction.toLowerCase()}`}
        >
            <ModalHeader toggle={toggleModal} className="onsolve-language-modal-header px-3">
                <GlobusIcon className="onsolve-home-globe" color="primary" size="lg" />
                <span className="onsolve-modal-heading">
                    <FormattedMessage id="ng_languages" defaultMessage="Languages" />
                </span>
            </ModalHeader>
            <ModalBody className="onsolve-language-dropdown-list">
                <LanguageList currentLanguage={currentLanguage} onLanguageChange={handleLanguageChange} />
            </ModalBody>
        </Modal>
    );
};

LanguageModal.propTypes = {
    afterLanguageChange: PropTypes.func.isRequired,
    currentLanguage: PropTypes.shape({
        direction: PropTypes.string.isRequired,
    }).isRequired,
    toggleModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
};

LanguageModal.defaultProps = {
    afterLanguageChange: () => {},
};

export default LanguageModal;
