import { responseService } from 'services';

const defaultHandledErrors = [400, 401, 403, 404, 409, 422];

const handleByDefault = (response) => {
    responseService.convertErrorMessages(response);
};

const handleAll = (response, errorMap, useDefault) => {
    const hasErrorMap = !!errorMap && !!errorMap[response.status];

    if (!hasErrorMap && useDefault && defaultHandledErrors.includes(response.status)) {
        handleByDefault(response);

        return true;
    } else if (hasErrorMap) {
        const handler = errorMap[response.status];

        if (handler) {
            handler();

            return true;
        }
    }

    return false;
};

/**
 * Handles the error response using the errorMap object.
 *
 * If no errorMap object is provided, or if the errorMap object does not contain
 * the response status, this method will use default error handling.
 * @param {{status: number}} response
 * @param {{[key: number]: () => void}} errorMap
 * @returns {boolean} Whether any handler was called
 */
const handle = (response, errorMap) => handleAll(response, errorMap, true);

/**
 * Handles the error response using only the errorMap object.
 *
 * @param {{status: number}} response
 * @param {{[key: number]: () => void}} errorMap
 * @returns {boolean} Whether any handler was called
 */
const handleExclusive = (response, errorMap) => handleAll(response, errorMap, false);

export default {
    handle,
    handleExclusive,
};
