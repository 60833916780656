import { defineMessages } from 'react-intl';

const forgotCredentialsTranslations = defineMessages({
    ng_forgotCredentials_forgotUsername: {
        id: 'identityServer.selfRegistration.forgotCredentials.forgotUsername',
        defaultMessage: 'Forgot username?',
    },
    ng_forgotCredentials_forgotYourUsername: {
        id: 'identityServer.selfRegistration.forgotCredentials.forgotYourUsername',
        defaultMessage: 'Forgot your username?',
    },
    ng_forgotCredentials_forgotYourUsernameHelp: {
        id: 'identityServer.selfRegistration.forgotCredentials.forgotYourUsernameHelp',
        defaultMessage: 'Enter your email below to receive an email with a link to retrieve your username.',
    },
    ng_forgotCredentials_forgotPassword: {
        id: 'identityServer.selfRegistration.forgotCredentials.forgotPassword',
        defaultMessage: 'Forgot password?',
    },
    ng_forgotCredentials_forgotYourPassword: {
        id: 'identityServer.selfRegistration.forgotCredentials.forgotYourPassword',
        defaultMessage: 'Forgot your password?',
    },
    ng_forgotCredentials_forgotYourPasswordHelp: {
        id: 'identityServer.selfRegistration.forgotCredentials.forgotYourPasswordHelp',
        defaultMessage: 'Enter your username below to receive an email with a link to reset your password.',
    },
    ng_forgotCredentials_RetrievePassword: {
        id: 'identityServer.selfRegistration.forgotCredentials.retrievePassword',
        defaultMessage: 'Retrieve your password',
    },
    ng_forgotCredentials_RetrievePasswordBody: {
        id: 'identityServer.selfRegistration.forgotCredentials.retrieve.passwordBody',
        defaultMessage:
            'An email has been sent to the email address on file with instructions on how to reset your password.',
    },
    ng_forgotCredentials_RetrieveCredentialsFooter: {
        id: 'identityServer.selfRegistration.forgotCredentials.retrieve.credentialsFooter',
        defaultMessage: 'Did not receive the email? Please check your junk mail folder or',
    },
    ng_forgotCredentials_RetrieveCredentialsLink: {
        id: 'identityServer.selfRegistration.forgotCredentials.retrieve.credentialsLink',
        defaultMessage: 'click here to resend.',
    },
    ng_forgotCredentials_RetrieveUsername: {
        id: 'identityServer.selfRegistration.forgotCredentials.retrieve.username',
        defaultMessage: 'Retrieve your username',
    },
    ng_forgotCredentials_RetrieveUsernameBody: {
        id: 'identityServer.selfRegistration.forgotCredentials.retrieve.usernameBody',
        defaultMessage: 'An email has been sent to the email address on file with your username.',
    },
    ng_forgotCredentials_RetrieveUsernameBodySms: {
        id: 'identityServer.selfRegistration.forgotCredentials.retrieve.usernameBodySms',
        defaultMessage: 'An sms has been sent to the phone number address on file with your username.',
    },
    ng_forgotCredentials_specifyMethod: {
        id: 'identityServer.selfRegistration.forgotCredentials.specifyMethod',
        defaultMessage: 'Please specify a recovery method to start the recovery process.',
    },
    ng_forgotCredentials_enterEmail: {
        id: 'identityServer.selfRegistration.forgotCredentials.enterEmail',
        defaultMessage: 'Please enter your email address to start the recovery process.',
    },
    ng_forgotCredentials_enterPhoneNumber: {
        id: 'identityServer.selfRegistration.forgotCredentials.enterPhoneNumber',
        defaultMessage: 'Please enter your phone number to start the recovery process.',
    },
    ng_forgotCredentials_enterUsername: {
        id: 'identityServer.selfRegistration.forgotCredentials.enterUsername',
        defaultMessage: 'Please enter your username to start the recovery process.',
    },
    ng_forgotCredentials_recoverUsername: {
        id: 'identityServer.selfRegistration.forgotCredentials.recoverUsername',
        defaultMessage: 'Recover username',
    },
    ng_forgotCredentials_recoverPassword: {
        id: 'identityServer.selfRegistration.forgotCredentials.recoverPassword',
        defaultMessage: 'Recover password',
    },
    ng_forgotCredentials_emailWillBeSent: {
        id: 'identityServer.selfRegistration.forgotCredentials.emailWillBeSent',
        defaultMessage:
            'An email will be sent to the email provided if you are located in the system. Additional instructions on how to recover your username will be included.',
    },
    ng_forgotCredentials_smsWillBeSent: {
        id: 'identityServer.selfRegistration.forgotCredentials.smsWillBeSent',
        defaultMessage:
            'An SMS Message will be sent to the number provided if you are located in the system. Additional instructions on how to recover your username will be included.',
    },
    ng_forgotCredentials_password_emailWillBeSent: {
        id: 'identityServer.selfRegistration.forgotCredentials.password.emailWillBeSent',
        defaultMessage:
            'An email was sent to the email. Additional instructions on how to recover your password will be included.',
    },
    ng_forgotCredentials_returnToLogin: {
        id: 'identityServer.selfRegistration.forgotCredentials.returnToLogin',
        defaultMessage: 'Return to login',
    },
    ng_forgotCredentials_didntReceiveEmail: {
        id: 'identityServer.selfRegistration.forgotCredentials.didntReceiveEmail',
        defaultMessage: 'Didn’t receive the email/text? Please check your junk folder or ',
    },
    ng_forgotCredentials_clickSendAgain: {
        id: 'identityServer.selfRegistration.forgotCredentials.sendAgain',
        defaultMessage: 'click here to send again',
    },
    ng_forgotCredentials_accountRecovery: {
        id: 'identityServer.selfRegistration.forgotCredentials.accountRecovery',
        defaultMessage: 'Account Recovery',
    },
    ng_forgotCredentials_recoveryMethod: {
        id: 'identityServer.selfRegistration.forgotCredentials.recoveryMethod',
        defaultMessage: 'Recovery method*',
    },
    ng_forgotCredentials_selectRecoveryMethod: {
        id: 'identityServer.selfRegistration.forgotCredentials.selectRecoveryMethod',
        defaultMessage: 'Select a recovery method',
    },
});

export default forgotCredentialsTranslations;
