import { defineMessages } from 'react-intl';

const selfRegistrationSignInTranslations = defineMessages({
    ng_customSignIn_register: {
        id: 'identityServer.selfRegistration.signIn.register',
        defaultMessage: 'Register',
    },
    ng_customSignIn_signInToYourAccount: {
        id: 'identityServer.selfRegistration.signIn.signInToYourAccount',
        defaultMessage: 'Sign In to your account',
    },
    ng_customSignIn_enterUsernameAndPassword: {
        id: 'identityServer.selfRegistration.signIn.enterUsernameAndPassword',
        defaultMessage: 'Enter your username and password. Passwords are case-sensitive.',
    },
    ng_customSignIn_rememberMe: {
        id: 'identityServer.selfRegistration.signIn.rememberMe',
        defaultMessage: 'Remember me',
    },
    ng_customSignIn_needAnAccount: {
        id: 'identityServer.selfRegistration.signIn.needAnAccount',
        defaultMessage: 'Need an Account?',
    },
    ng_customSignIn_geInformationLabel: {
        id: 'identityServer.selfRegistration.signIn.getInformationLabel',
        defaultMessage: 'Let’s get your information so you may start receiving notifications from',
    },
    ng_customSignIn_notRegisteredYet: {
        id: 'identityServer.selfRegistration.signIn.notRegisteredYet',
        defaultMessage: 'Not a registered user yet?',
    },
});

export default selfRegistrationSignInTranslations;
