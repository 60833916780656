export function setFocus(element) {
    if (element) {
        if (element.current) {
            element.current.focus();
        } else {
            element.focus();
        }
    }
}

export function getCharCode(e) {
    return e.charCode || e.keyCode || e.which;
}
