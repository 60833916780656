import { defineMessages } from 'react-intl';

const errorTranslations = defineMessages({
    1000: {
        id: 'identityServer.errors.server.userNameConflict',
        defaultMessage: 'The specified user name is already in use.',
    },
    1001: {
        id: 'identityServer.errors.server.passwordNotStrongEnough',
        defaultMessage: 'The password supplied does not meet the requried strength checks.',
    },
    1002: {
        id: 'identityServer.errors.server.userNameEmpty',
        defaultMessage: 'Username null or whitespace.',
    },
    1003: {
        id: 'identityServer.errors.server.passwordEmpty',
        defaultMessage: 'Password null or whitespace.',
    },
    1004: {
        id: 'identityServer.errors.server.contextIdEmpty',
        defaultMessage: 'Context ID is empty',
    },
    1005: {
        id: 'identityServer.errors.server.invalidSubjectId',
        defaultMessage: 'SubjectId is null, empty, or consists only of white-space',
    },
    1006: {
        id: 'identityServer.errors.server.userNotFound',
        defaultMessage: 'The specified user not found.',
    },
    1007: {
        id: 'identityServer.errors.server.userUpdateFailed',
        defaultMessage: 'Failed to update user.',
    },
    1008: {
        id: 'identityServer.errors.server.userCreateFailed',
        defaultMessage: 'Failed to create user.',
    },
    1009: {
        id: 'identityServer.errors.server.userNameTooLong',
        defaultMessage: 'Username is too long.',
    },
    1010: {
        id: 'identityServer.errors.server.passwordTooLong',
        defaultMessage: 'Password is too long.',
    },
    1011: {
        id: 'identityServer.errors.server.apiKeyEmpty',
        defaultMessage: 'MIR3 api key is null or whitespace.',
    },
    1012: {
        id: 'identityServer.errors.server.invalidReturnUrl',
        defaultMessage: 'Invalid ReturnUrl.',
    },
    1013: {
        id: 'identityServer.errors.server.invalidNamespace',
        defaultMessage: 'Invalid namespace.',
    },
    1014: {
        id: 'identityServer.errors.server.recipientCreateFailed',
        defaultMessage: 'Failed to create a recipient.',
    },
    1015: {
        id: 'identityServer.errors.server.invalidDivisionUuid',
        defaultMessage: 'Invalid division uuid.',
    },
    1016: {
        id: 'identityServer.errors.server.invalidOrganizationUuid',
        defaultMessage: 'Invalid organization uuid.',
    },
    1017: {
        id: 'identityServer.errors.server.userRegisterIsNotAllowed',
        defaultMessage: 'User registration is not allowed.',
    },
    1018: {
        id: 'identityServer.errors.server.invalidOrDisabledClient',
        defaultMessage: 'Invalid or disabled client.',
    },
    1019: {
        id: 'identityServer.errors.server.deviceNotFound',
        defaultMessage: 'Device is not found',
    },
    1020: {
        id: 'identityServer.errors.server.deviceAlreadyRegistered',
        defaultMessage: 'Device is already registered',
    },
    1021: {
        id: 'identityServer.errors.server.deviceVerificationCodeNotValid',
        defaultMessage: 'Device verification code is not valid',
    },
    1022: {
        id: 'identityServer.errors.server.resetPasswordTokenIsNotValid',
        defaultMessage: 'Reset password token is not valid',
    },
    1023: {
        id: 'identityServer.errors.server.failedToMigrateUser',
        defaultMessage: 'Failed to migrate user',
    },
    1024: {
        id: 'identityServer.errors.server.identityContextNotFound',
        defaultMessage: 'IdentityContext is not found',
    },
    1025: {
        id: 'identityServer.errors.server.unexpectedException',
        defaultMessage: 'Unexpected Exception',
    },
    1026: {
        id: 'identityServer.errors.server.invaliduserName',
        defaultMessage: 'Invalid username',
    },
    1027: {
        id: 'identityServer.errors.server.invalidPin',
        defaultMessage: 'Invalid pin',
    },
    1028: {
        id: 'identityServer.errors.client.onPermissionToLogin',
        defaultMessage: 'No permission to log into the system',
    },
    1029: {
        id: 'identityServer.errors.client.ExpiredSigninLink',
        defaultMessage:
            'The sign in link has expired. Please sign in again. If you have bookmarked this page, please update it to your Client Workspace URL',
    },
    1030: {
        id: 'identityServer.errors.client.UnsupportedDeviceType',
        defaultMessage: 'Device type is not supported.',
    },
    1031: {
        id: 'identityServer.errors.client.MultipleAssociatedDevice',
        defaultMessage: 'Device associated to multiple users. Contact Customer Support for assistance.',
    },
    1032: {
        id: 'identityServer.errors.client.ForgotCredentialsFailed',
        defaultMessage: 'Failed to execute Forgot Credentials.',
    },
    1033: {
        id: 'identityServer.errors.client.InvalidForgotCredentialsModel',
        defaultMessage: 'Invalid forgot credentials model.',
    },
    1034: {
        id: 'identityServer.errors.client.InvalidCaptcha',
        defaultMessage: 'Invalid captcha.',
    },
    1036: {
        id: 'identityServer.errors.client.NotEnoughPermissionToForgotUserName',
        defaultMessage: 'You do not have permission to Retrieve Username.',
    },
    USER_LOCKED: {
        id: 'identityServer.errors.client.accountHasBeenLocked',
        defaultMessage:
            'Your account has been locked. Please contact your organization administrator or customer support.',
    },
    1047: {
        id: 'identityServer.errors.client.noEmailOrSms',
        defaultMessage:
            'There are no user devices connected to the account, please contact customer support or admin for assistance.',
    },
    signInFormBothFieldsError: {
        id: 'identityServer.errors.signIn.bothFields',
        defaultMessage: 'Your username and password are required.',
    },
    signInFormUsernameError: {
        id: 'identityServer.errors.signIn.username',
        defaultMessage: 'Your username is required.',
    },

    signInFormPasswordError: {
        id: 'identityServer.errors.signIn.password',
        defaultMessage: 'Your password is required.',
    },
    signInFormIncorrectUserNameOrPassword: {
        id: 'identityServer.errors.signIn.incorrectUserNameOrPassword',
        defaultMessage: 'Your username and/or password are incorrect.',
    },
    recaptchaError: {
        id: 'identityServer.errors.common.recaptcha',
        defaultMessage: 'Please check your captcha settings',
    },
    recaptchaValidation: {
        id: 'identityServer.errors.common.recaptchaValidation',
        defaultMessage: 'Please complete the recaptcha',
    },
    pageNotFound: {
        id: 'identityServer.errors.common.pageNotFound',
        defaultMessage: 'Page Not Found',
    },
    internalServerError: {
        id: 'identityServer.errors.common.internalServerError',
        defaultMessage: 'Internal Server Error',
    },
    internalServerErrorUnauthMessage: {
        id: 'identityServer.errors.common.unauthMessage',
        defaultMessage: 'This page does not exist. Please check your URL or return to the sign in.',
    },
    internalServerErrorMessage: {
        id: 'identityServer.errors.common.internalServerErrorMessage',
        defaultMessage:
            'We have encountered an unexpected error and are working towards resolving this issue. Please try again later.',
    },

    forgotCredentialsFromConfirmPasswordError: {
        id: 'identityServer.errors.forgotCredentials.confirmPassword',
        defaultMessage: 'Passwords do not match. Please try again.',
    },
    forgotCredentialsFormEmailRequired: {
        id: 'identityServer.errors.forgotCredentials.emailRequired',
        defaultMessage: 'Your email is required',
    },
    forgotCredentialsFormInvalidEmail: {
        id: 'identityServer.errors.contactMethod.InvalidEmail',
        defaultMessage: 'Email is not valid',
    },

    passwordCannotBeReusedError: {
        id: 'identityServer.errors.resetPassword.passwordCannotBeReusedError',
        defaultMessage: 'You may not reuse your most recent {count} password(s).',
    },

    accountConfirmationFormInvalidPin: {
        id: 'identityServer.errors.accountConfirmation.InvalidPin',
        defaultMessage: 'You have entered an invalid PIN. Please try again.',
    },

    contactMethodFormInvalidPhoneNumber: {
        id: 'identityServer.errors.contactMethod.invalidPhoneNumber',
        defaultMessage: 'Phone number is not valid',
    },
    contactMethodFormInvalidEmail: {
        id: 'identityServer.errors.contactMethod.InvalidEmail',
        defaultMessage: 'Email is not valid',
    },
    contactMethodFormForbiddenEmail: {
        id: 'identityServer.errors.contactMethod.forbiddenEmail',
        defaultMessage: 'Specified email is not allowed',
    },
    signUpFormUsernameError: {
        id: 'identityServer.errors.signUp.username',
        defaultMessage: 'Username not available. Please try again.',
    },
    signUpFormPasswordError: {
        id: 'identityServer.errors.signUp.password',
        defaultMessage: 'Password not available. Please try again.',
    },
    signUpFormFirstnameError: {
        id: 'identityServer.errors.signUp.firstname',
        defaultMessage: 'First name not available. Please try again.',
    },
    signUpFormLastnameError: {
        id: 'identityServer.errors.signUp.lastname',
        defaultMessage: 'Last name not available. Please try again.',
    },
    popupErrorCode: {
        id: 'identityServer.errors.popupErrorCode',
        defaultMessage: 'Error: {serverCode}',
    },
    popupErrorMessage: {
        id: 'identityServer.errors.popupErrorMessage',
        defaultMessage: 'Unfortunately, an issue is on the server side. You can try again or contact with our support',
    },
    namespaceInvalid: {
        id: 'identityServer.errors.namespaceInvalid',
        defaultMessage: 'The namespace you have entered is not valid, please use a valid namespace to sign in.',
    },
    passwordExpired: {
        id: 'identityServer.errors.passwordExpired',
        defaultMessage: 'Your password has expired and must be changed.',
    },
    EXTERNAL_USER_NOT_FOUND: {
        id: 'identityServer.errors.external.userNotFound',
        defaultMessage:
            'Unable to authenticate user. Your contact record cannot be found. Please contact your organization administrator.',
    },
    EXTERNAL_AUTH_FAILED: {
        id: 'identityServer.errors.external.authFailed',
        defaultMessage: 'The request cannot be authenticated. Please contact customer support.',
    },
    NOT_FULLY_REGISTERED_USER_PWD_RESET: {
        id: 'identityServer.errors.client.notFullyRegisteredUserPwdReset',
        defaultMessage: 'Failed to send Forgot password link for not fully registered user.',
    },
    securityQuestionInvalid: {
        id: 'identityServer.errors.securityQuestions.question',
        defaultMessage: 'Please enter your security question to continue.',
    },
    securityAnswerInvalid: {
        id: 'identityServer.errors.securityQuestions.answerInvalid',
        defaultMessage: 'Please enter your security answer to continue.',
    },
    securityQuestionAndAnswerInvalid: {
        id: 'identityServer.errors.securityQuestions.questionAndAnswerInvalid',
        defaultMessage: 'Please enter your security question and answer to continue.',
    },
    securityWrongAnswerMaxAttempts: {
        id: 'identityServer.errors.securityQuestions.wrongAnswerMaxAttempts',
        defaultMessage:
            'We are sorry, but the maximum number of attempts has been reached. Please retry after {lockOutTime} minutes or contact Customer Support for immediate assistance.',
    },
    securityAnswerWrongWithAttempts: {
        id: 'identityServer.errors.securityQuestions.answerWrongWithAttempts',
        defaultMessage: 'Incorrect answer. You have {remainingAttempts} of {maxAttempts} attempts remaining',
    },
    securityAnswerWrong: {
        id: 'identityServer.errors.securityQuestions.answerWrong',
        defaultMessage: 'Incorrect answer',
    },
    MFA_USER_ALREADY_ENROLLED: {
        id: 'identityServer.errors.mfa.userAlreadyEnrolled',
        defaultMessage: 'User has already enrolled for MFA.',
    },
    MFA_USER_LOCKED: {
        id: 'identityServer.errors.mfa.userIsLocked',
        defaultMessage: 'Your account has been locked. Please contact your org administrator or customer support.',
    },
    MFA_MULTIPLE_ASSOCIATED_DEVICE: {
        id: 'identityServer.errors.mfa.multipleAssociatedDevice',
        defaultMessage: 'Device associated to multiple users. Please contact System Administrator for assistance.',
    },
    organizationIsInaccessible: {
        id: 'identityServer.errors.organizationIsInaccessible',
        defaultMessage:
            'This organization is not currently accessible.  Please contact your administrator or customer support.',
    },
});

export default errorTranslations;
