import PropTypes from 'prop-types';

const responseHandlerService = {
    handle: (response, event200, event300, event400, event500, customEvents) => {
        if (!response) {
            return false;
        }

        if (customEvents) {
            const isSuccessed = customEvents(response);

            if (isSuccessed) {
                return true;
            }
        }

        switch (true) {
            case 200 <= response.status && response.status < 300:
                event200();
                return true;
            case 300 <= response.status && response.status < 400:
                event300();
                return true;
            case 400 <= response.status && response.status < 500:
                event400();
                return true;
            case 500 <= response.status && response.status < 600:
                event500();
                return true;
            default:
                return false;
        }
    },
};

responseHandlerService.handle.propTypes = {
    event200: PropTypes.func.isRequired,
    response: PropTypes.object.isRequired,
    customEvents: PropTypes.func,
    event300: PropTypes.func,
    event400: PropTypes.func,
    event500: PropTypes.func,
};

export default responseHandlerService;
