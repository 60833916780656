import React from 'react';
import { LoadingOverlay } from 'onsolve-ui-components';

import './style.scss';

const LoadingBarSection = (props) => (
    <LoadingOverlay className="onsolve-loading-bar__wrapper" logoType="newLogo" {...props} />
);

LoadingBarSection.displayName = 'LoadingBarSection';

export default LoadingBarSection;
