import { defineMessages } from 'react-intl';

const signInTranslations = defineMessages({
    ng_signIn_or: {
        id: 'identityServer.common.or',
        defaultMessage: 'or',
    },
    ng_signIn_signIn: {
        id: 'identityServer.selfRegistration.signIn',
        defaultMessage: 'Sign In',
    },
    ng_signIn_helpCenter: {
        id: 'identityServer.selfRegistration.signIn.helpCenter',
        defaultMessage: 'Help Center',
    },
    ng_signIn_signInDescription: {
        id: 'identityServer.selfRegistration.signIn.description',
        defaultMessage: 'Enter your username and password.',
    },
    ng_signIn_forgot: {
        id: 'identityServer.common.forgot',
        defaultMessage: 'Forgot',
    },
    ng_signIn_submit: {
        id: 'identityServer.common.Submit',
        defaultMessage: 'Submit',
    },
    ng_signIn_contactAccountManagerOr: {
        id: 'identityServer.signIn.contactAccountManagerOr',
        defaultMessage: 'Contact your Account Manager or {0}.',
    },
    ng_signIn_dontKnowYourWorkspace: {
        id: 'identityServer.signIn.dontKnowYourWorkspace',
        defaultMessage: "Don't know your organization's workspace?",
    },
    ng_signIn_getStarted: {
        id: 'identityServer.signIn.getStarted',
        defaultMessage: 'Get started',
    },
    ng_signIn_enterYourWorkspace: {
        id: 'identityServer.signIn.enterYourWorkspace',
        defaultMessage: 'Enter your workspace',
    },
    ng_signIn_enterYourWorkspaceToSignIn: {
        id: 'identityServer.signIn.enterYourWorkspaceToSignIn',
        defaultMessage: "Enter your workspace's OnSolve URL to sign in.",
    },
    ng_signIn_letsFindYourOrganization: {
        id: 'identityServer.signIn.letsFindYourOrganization',
        defaultMessage: "Let's find your organization",
    },
    ng_signIn_onsolveCustomerJourneyTeam: {
        id: 'identityServer.signIn.onsolveCustomerJourneyTeam',
        defaultMessage: "OnSolve's Customer Journey Team",
    },
    ng_signIn_externalSignIn: {
        id: 'ng_signIn_externalSignInTitle',
        defaultMessage: 'Or Sign In With ',
    },
    ng_signInWithYourOrg: {
        id: 'ng_signInWithYourOrg',
        defaultMessage: 'Your Organization Credentials',
    },
    ng_signIn_onsolveCustomerSupportTeam: {
        id: 'identityServer.signIn.onsolveCustomerSupportTeam',
        defaultMessage: "OnSolve's Customer Support Team",
    },
});

export default signInTranslations;
