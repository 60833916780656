import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const NotificationBox = ({ message, variant, className }) => {
    const defaultColor = variant || 'primary';

    return (
        <div className={classNames(`onsolve-notification-box onsolve-notification-box--${defaultColor}`, className)}>
            <span className="onsolve-notification-box__text">{message}</span>
        </div>
    );
};

NotificationBox.propTypes = {
    className: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    variant: PropTypes.oneOf(['primary', 'success', 'danger']),
};

export default NotificationBox;
