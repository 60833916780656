import { defineMessages } from 'react-intl';

const sharedTranslations = defineMessages({
    ng_shared_termsAndConditionsOfUse: {
        id: 'identityServer.footer.termsAndConditionsOfUse',
        defaultMessage: 'Terms and Conditions of Use:',
    },
    ng_shared_TAC: {
        id: 'identityServer.footer.TAC',
        defaultMessage:
            'Welcome to the OnSolve customer login site. The terms and conditions concerning your use of the site and your account are set forth in your contractual agreement with OnSolve. By accessing, using or downloading any material from the site or your account, you agree to follow and are bound by those terms and conditions. This is a U.S. Government Information System subject to Federal Law. Unauthorized attempts to upload or otherwise alter data, programming language, or any other part of this system are strictly prohibited and are subject to civil action and/or criminal penalties. The system is for the use of authorized users only. Individuals using the system with or without authority, or in access of their authority, are subject to having all of their activities on this system monitored and recorded by system personnel. Anyone using the system expressly consents to such monitoring and recording, and is advised that if such monitoring reveals possible evidence of criminal activity, your organization may provide the evidence of such monitoring to law enforcement.',
    },
    ng_wtp_TAC: {
        id: 'identityServer.footer.wtp.TAC',
        defaultMessage:
            'Welcome to the Travel Assist customer login site (Site). The terms and conditions concerning your use of the Site and your Travel Assist account are set forth in the <a>{0}</a> (EULA) or your contractual agreement with World Travel Protection Pty Ltd or World Travel Protection Canada Inc, as applicable. By accessing, using or downloading any material from the Site or your Account, you agree to follow and are bound by those terms and conditions. By creating your Account, you are being granted a non-exclusive, non-transferable, non-sharable, revocable, limited license to use the Site solely for personal, non-commercial use in accordance with the EULA. We may make changes to the terms from time to time. If we make changes, we will post the amended terms on the Site. Unless we indicate otherwise, the amended EULA will be effective immediately and your continued use of the Site will confirm your acceptance of the change.',
    },
    ng_wtp_EULA: {
        id: 'identityServer.footer.wtp.EULA',
        defaultMessage: 'End User Licence Agreement',
    },
    ng_generic_PoweredByOnSolve: {
        id: 'identityServer.footer.poweredByOnSolve',
        defaultMessage: 'Powered By OnSolve',
    },
    ng_generic_DataSubjectRightsRequest: {
        id: 'identityServer.footer.rtbf.text',
        defaultMessage: 'Data Subject Rights Request',
    },
    ng_generic_privacyStatement: {
        id: 'identityServer.footer.privacyStatement',
        defaultMessage: 'Privacy Statement',
    },
    ng_generic_copyrightNotice: {
        id: 'identityServer.footer.copyrightNotice',
        defaultMessage: 'Copyright Notice',
    },
    ng_generic_contactUs: {
        id: 'identityServer.footer.contactUs',
        defaultMessage: 'Contact Us',
    },
    ng_generic_username: {
        id: 'identityServer.common.username',
        defaultMessage: 'Username',
    },
    ng_generic_usernamePlaceholder: {
        id: 'identityServer.common.username.placeholder',
        defaultMessage: 'Enter username',
    },
    ng_generic_emailPlaceholder: {
        id: 'identityServer.common.emailPlaceholder',
        defaultMessage: 'Enter email',
    },
    ng_generic_email: {
        id: 'identityServer.common.email',
        defaultMessage: 'Email',
    },
    ng_generic_customerCommunity: {
        id: 'identityServer.common.customerCommunity',
        defaultMessage: 'Customer Community',
    },
    ng_generic_phoneNumber: {
        id: 'identityServer.common.phoneNumber',
        defaultMessage: 'Phone number',
    },
    ng_generic_password: {
        id: 'identityServer.common.password',
        defaultMessage: 'Password',
    },
    ng_generic_passwordPlaceholder: {
        id: 'identityServer.common.password.placeholder',
        defaultMessage: 'Enter password',
    },
    ng_generic_languages: {
        id: 'identityServer.common.languages',
        defaultMessage: 'Languages',
    },
    ng_generic_cancel: {
        id: 'identityServer.common.button.cancel',
        defaultMessage: 'Cancel',
    },
    ng_generic_save: {
        id: 'identityServer.common.button.save',
        defaultMessage: 'Save',
    },
    ng_generic_close: {
        id: 'identityServer.common.button.close',
        defaultMessage: 'Close',
    },
    ng_generic_showPassword: {
        id: 'identityServer.common.button.showPassword',
        defaultMessage: 'Show password',
    },
    ng_generic_backToSignin: {
        id: 'ng_generic_backToSignin',
        defaultMessage: 'Back to sign in',
    },
    ng_generic_continue: {
        id: 'identityServer.common.continue',
        defaultMessage: 'Continue',
    },
});

export default sharedTranslations;
