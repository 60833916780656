import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './OrganizationTitle.scss';

const OrganizationTitle = ({ title, className, color = 'primary' }) => {
    return title ? (
        <div
            className={classNames(
                'onsolve-organization-title',
                'text-overflow-break-word',
                className,
                `color-${color}`
            )}
        >
            <h3>{title}</h3>
        </div>
    ) : null;
};

OrganizationTitle.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(['background', 'primary']),
    title: PropTypes.string,
};

export default OrganizationTitle;
