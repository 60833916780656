import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

export const FormattedErrorMessageText = ({ error, intl }) => {
    let message;

    if (!error) {
        return false;
    }

    if (!error.id || error.id === 'identityserver.free') {
        message = error.defaultMessage;
    } else {
        message = intl.formatMessage(error);
    }

    return <>{message}</>;
};

FormattedErrorMessageText.propTypes = {
    error: PropTypes.object.isRequired,
    intl: PropTypes.object,
};

export default injectIntl(FormattedErrorMessageText);
