import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'onsolve-ui-components';

import { localeService } from 'services';

const LanguageList = ({ currentLanguage, onLanguageChange }) => {
    const languageList = localeService.locales;

    return (
        <div className="onsolve-underline-text onsolve-language-dropdown-scroll">
            <Scrollbars vertical>
                <div className="onsolve-language-container" key={languageList.length}>
                    {languageList.map((language) => (
                        <div
                            className={classNames('onsolve-active-list', {
                                'onsolve-active-list--selected': currentLanguage.lcId === language.lcId,
                                'onsolve-rtl-lang-item': language.direction === 'RTL',
                            })}
                            key={language.lcId}
                            onClick={(e) => onLanguageChange(e, language)}
                            onKeyDown={(e) => onLanguageChange(e, language)}
                        >
                            <button
                                type="button"
                                href="#"
                                className="onsolve-lang btn btn-link p-0 border-0"
                                aria-label={language.translatedName}
                                title={language.translatedName}
                            >
                                <span>{language.translatedName}</span>
                                <span className="text-uppercase">({language.cultureRegionCode})</span>
                            </button>
                        </div>
                    ))}
                </div>
            </Scrollbars>
        </div>
    );
};

LanguageList.propTypes = {
    currentLanguage: PropTypes.shape({
        lcId: PropTypes.string.isRequired,
    }).isRequired,
    onLanguageChange: PropTypes.func.isRequired,
};

LanguageList.defaultProps = {
    changeLanguage: () => {},
    onLanguageChange: () => {},
};

export default LanguageList;
