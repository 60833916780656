import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'onsolve-ui-components';
import { FormattedMessage } from 'react-intl';

const TypographySection = ({ typographyVariant, typographyClass, formattedMessage, message }) => {
    return (
        <>
            <Typography variant={typographyVariant} className={typographyClass}>
                <FormattedMessage {...formattedMessage} />
                <br />
                <span> {message} </span>
            </Typography>
        </>
    );
};

TypographySection.propTypes = {
    formattedMessage: PropTypes.object,
    message: PropTypes.object,
    typographyClass: PropTypes.string,
    typographyVariant: PropTypes.string,
};

export default TypographySection;
