import React from 'react';
import { ClientContext } from './clientContext';

export function withClientContext(Component) {
    return function ClientContextComponent(props) {
        return (
            <ClientContext.Consumer>
                {(context) => <Component {...props} clientContext={context} />}
            </ClientContext.Consumer>
        );
    };
}
