import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Focusable, GlobusIcon, IconButton, SortIcon, TimesIcon } from 'onsolve-ui-components';

import { sharedTranslations } from 'translations';
import { KeyCodes } from 'common/constants';

import LanguageList from './LanguageList';

const LanguageDropdown = ({ isOpen, currentLanguage, toggleDropdown, renderClose, iconProps, afterLanguageChange }) => {
    const handleLanguageChange = (event, language) => {
        const { ENTER_KEY, TAB } = KeyCodes;

        if (event.type === 'click' || (event.type === 'keydown' && event.keyCode === ENTER_KEY)) {
            afterLanguageChange(language);
        } else if (event.type === 'keydown' && event.keyCode !== ENTER_KEY && event.keyCode !== TAB) {
            event.preventDefault();
        }
    };

    return (
        <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
            <Focusable
                render={({ classes }) => (
                    <DropdownToggle
                        className={`d-flex align-items-center text-decoration-none p-1 pl-2 onsolve-language-button ${classes}`}
                        aria-label={`${currentLanguage.translatedName} ${currentLanguage.cultureRegionCode}`}
                        onKeyDown={toggleDropdown}
                    >
                        <span className="mr-1">{currentLanguage.translatedName}</span>
                        <span className="text-uppercase mx-0">({currentLanguage.cultureRegionCode})</span>
                        <SortIcon className="mx-2" {...iconProps} />
                    </DropdownToggle>
                )}
            />
            <DropdownMenu
                className="onsolve-language-wrapper dropdown-menu"
                right={currentLanguage.direction.toLowerCase() !== 'rtl'}
            >
                <div className="onsolve-card card">
                    <div className={`card-header onsolve-card-header ${currentLanguage.direction.toLowerCase()} `}>
                        <div className="onsolve-lang-title">
                            <GlobusIcon className="onsolve-home-globe" color="primary" size="lg" />
                            <span className="onsolve-lang-heading">
                                <FormattedMessage {...sharedTranslations.ng_generic_languages} />
                            </span>
                        </div>
                        {renderClose ? (
                            <IconButton size="xs" onClick={toggleDropdown} tabIndex="0">
                                <TimesIcon color="secondary" size="sm" />
                            </IconButton>
                        ) : null}
                    </div>
                    <div className="onsolve-language-dropdown-list">
                        <LanguageList currentLanguage={currentLanguage} onLanguageChange={handleLanguageChange} />
                    </div>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

LanguageDropdown.propTypes = {
    afterLanguageChange: PropTypes.func.isRequired,
    currentLanguage: PropTypes.shape({
        direction: PropTypes.string.isRequired,
        cultureRegionCode: PropTypes.string,
        translatedName: PropTypes.string,
    }).isRequired,
    toggleDropdown: PropTypes.func.isRequired,
    iconProps: PropTypes.object,
    isOpen: PropTypes.bool,
    renderClose: PropTypes.bool,
};

export default LanguageDropdown;
