/* eslint-disable react/display-name */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { MenuItem, Highlighter, Menu } from 'onsolve-ui-components';

import { KeyCodes } from 'common/constants/keyCodes';
import { DomUtils } from 'common/utility';
import { componentsTranslations } from 'translations';

const getMenuItem = (onClick, search, item, itemRender, handleKeyDown, order) => {
    const { distinctField } = item;
    const value = item[distinctField];

    return (
        <MenuItem key={order} order={order} value={value} item={item} onKeyDown={handleKeyDown} onClick={onClick}>
            {renderList(search, value, item, itemRender)}
        </MenuItem>
    );
};

const renderList = (search, value, item, itemRender) => {
    if (itemRender) {
        return itemRender({ search, value, item });
    }
    return (
        <span className="title">
            <Highlighter search={search}>{value}</Highlighter>
        </span>
    );
};

const SuggestionList = React.forwardRef((props, ref) => {
    const {
        autoFocus = false,
        emptyMessageRender,
        variant,
        search,
        suggestions: initialSuggestions,
        field,
        onClick,
        anchorEl,
        showSuggestionList,
        itemRender,
        onClose,
        onKeyDown,
        dataItemKey,
        className,
    } = props;

    let items = [];
    const startsWith = [];
    const containsWith = [];
    const [suggestion] = initialSuggestions;

    const suggestions =
        !suggestion || Object.keys(suggestion).includes(dataItemKey)
            ? initialSuggestions
            : initialSuggestions.map((item, index) => ({
                  ...item,
                  [dataItemKey]: index,
              }));

    const handleKeyDown = (e, value, { item }) => {
        e.preventDefault();

        const { ENTER_KEY } = KeyCodes;
        const charCode = DomUtils.getCharCode(e);

        if (charCode === ENTER_KEY) {
            onClick(e, value, { item });
        }

        if (onKeyDown) {
            onKeyDown(e);
        }
    };

    const orderedMenuItem = (list, startOrder) => {
        return list.map((item) => {
            const menuItem = getMenuItem(onClick, search, item, itemRender, handleKeyDown, startOrder);

            startOrder++;
            return menuItem;
        });
    };

    const makeList = (distinctField) => {
        items = suggestions.map((item, index) => {
            item['distinctField'] = distinctField;
            return getMenuItem(onClick, search, item, itemRender, handleKeyDown, index);
        });
    };

    const isSuggestionUsed = (list, suggestionItem) =>
        !!list.filter((item) => item[dataItemKey] === suggestionItem[dataItemKey]).length;

    const makeSuggestions = (distinctField) => {
        suggestions.forEach((item) => {
            const value = item[distinctField];
            const matches = value.toLowerCase().includes(search.toLowerCase());
            const suggestionItem = {
                ...item,
                distinctField: distinctField,
            };

            if (matches) {
                if (matches.index === 0 && !isSuggestionUsed(startsWith, suggestionItem)) {
                    startsWith.push(suggestionItem);
                } else if (
                    !isSuggestionUsed(containsWith, suggestionItem) &&
                    !isSuggestionUsed(startsWith, suggestionItem)
                ) {
                    containsWith.push(suggestionItem);
                }
            }
        });
    };

    if (typeof field === 'string') {
        if (variant === 'none') {
            makeList(field);
        } else {
            makeSuggestions(field);
        }
    }

    if (typeof field === 'object') {
        if (variant === 'none') {
            field.forEach((fieldItem) => {
                makeList(fieldItem);
            });
        } else {
            field.forEach((fieldItem) => {
                makeSuggestions(fieldItem);
            });
        }
    }

    if (startsWith.length) {
        items = [
            <MenuItem variant="header" key="startWith">
                <FormattedMessage {...componentsTranslations.ng_components_startsWith} />
            </MenuItem>,
            ...orderedMenuItem(startsWith, 0),
        ];
    }

    if (startsWith.length && containsWith.length) {
        items = [...items, <MenuItem variant="divider" key="divider" />];
    }

    if (containsWith.length) {
        items = [
            ...items,
            <MenuItem variant="header" key="contains">
                <FormattedMessage {...componentsTranslations.ng_components_contains} />
            </MenuItem>,
            ...orderedMenuItem(containsWith, startsWith.length),
        ];
    }

    return (
        <Menu
            anchorEl={anchorEl.current}
            innerRef={ref}
            autoFocus={autoFocus}
            open={showSuggestionList && (!!items.length || emptyMessageRender)}
            position="center"
            onClose={onClose}
            className={className}
            styles={{
                autoHeightMax: 315,
            }}
        >
            {!items.length && emptyMessageRender ? emptyMessageRender() : items}
        </Menu>
    );
});

SuggestionList.propTypes = {
    anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    dataItemKey: PropTypes.string,
    emptyMessageRender: PropTypes.func,
    field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    itemRender: PropTypes.func,
    search: PropTypes.string,
    showSuggestionList: PropTypes.bool,
    suggestions: PropTypes.arrayOf(Object),
    variant: PropTypes.oneOf(['none', 'other']),
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    onKeyDown: PropTypes.func,
};

SuggestionList.defaultProps = {
    dataItemKey: 'id',
    position: 'right',
};

export default SuggestionList;
