import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './DefaultCenterPanel.scss';

export const CenterPanelDefault = ({ children, className }) => (
    <div className={classNames('onsolve-default-center-panel', className)}>{children}</div>
);

export const CenterPanelDefaultPaddedContent = ({ children, className }) => (
    <div className={classNames('onsolve-default-center-panel__padded-content', className)}>{children}</div>
);

CenterPanelDefault.propTypes = {
    children: PropTypes.object,
    className: PropTypes.object,
};

CenterPanelDefaultPaddedContent.propTypes = {
    children: PropTypes.object,
    className: PropTypes.object,
};
