import { defineMessages } from 'react-intl';

const validationRulesTranslations = defineMessages({
    passwordValidationMinLength: {
        id: 'identityServer.validation.password.MinLength',
        defaultMessage: 'Minimum {number} characters',
    },
    passwordValidationRequireUpper: {
        id: 'identityServer.validation.password.RequireUpper',
        defaultMessage: 'Uppercase letters (A-Z)',
    },
    passwordValidationRequireLower: {
        id: 'identityServer.validation.password.RequireLower',
        defaultMessage: 'Lowercase letters (a-z)',
    },
    passwordValidationRequireDigit: {
        id: 'identityServer.validation.password.RequireDigit',
        defaultMessage: 'Number (i.e. 0-9)',
    },
    passwordValidationRequireSpecial: {
        id: 'identityServer.validation.password.RequireSpecial',
        defaultMessage: 'Special characters (!@#$)',
    },
    passwordValidationMinUniqueCharacters: {
        id: 'identityServer.validation.password.MinUniqueCharacters',
        defaultMessage: 'Minimum {minUnique} unique characters',
    },
    passwordValidationNotUsername: {
        id: 'identityServer.validation.password.NotUsername',
        defaultMessage: 'Not a username',
    },
});

export default validationRulesTranslations;
