import { stringify } from 'query-string';

const apiService = {
    get: async (resource, params = {}, options = {}) => {
        try {
            let responseJson;
            const response = await fetch(resource + '?' + stringify(params), options);

            try {
                responseJson = await response.json();
            } catch (e) {
                responseJson = null;
            }

            const result = {
                responseJson,
                status: response.status,
                statusText: response.statusText,
            };

            return result;
        } catch (error) {
            return null;
        }
    },

    post: async (resource, body = {}, options = {}) => {
        try {
            let responseJson;
            let defaultOptions = {
                ...options,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...options.headers,
                },
                body: JSON.stringify(body),
            };

            const response = await fetch(resource, defaultOptions);

            try {
                responseJson = await response.json();
            } catch (e) {
                responseJson = null;
            }

            const result = {
                responseJson,
                status: response.status,
                statusText: response.statusText,
            };

            return result;
        } catch (error) {
            return null;
        }
    },

    put: async (resource, body = {}) => {
        try {
            let responseJson;
            const options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            };

            const response = await fetch(resource, options);

            try {
                responseJson = await response.json();
            } catch (e) {
                responseJson = null;
            }

            const result = {
                responseJson,
                status: response.status,
                statusText: response.statusText,
            };

            return result;
        } catch (error) {
            return null;
        }
    },
};

export default apiService;
