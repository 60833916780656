import { defineMessages } from 'react-intl';

const contactUsTranslations = defineMessages({
    ng_contactUs_contactCustomerSupport: {
        id: 'identityServer.contactUs.contactCustomerSupport',
        defaultMessage: 'Contact Customer Support',
    },
    ng_contactUs_customerSupportAvailability: {
        id: 'identityServer.contactUs.customerSupportAvailability',
        defaultMessage:
            'OnSolve support is available 24x7x365. Our Customer Support team provides guidance with account management, data maintenance, best practices, and general troubleshooting.',
    },
    ng_contactUs_unitedStates: {
        id: 'identityServer.contactUs.unitedStates',
        defaultMessage: 'United States',
    },
    ng_contactUs_australia: {
        id: 'ng_contactUs_australia',
        defaultMessage: 'Australia',
    },
    ng_contactUs_northAmerica: {
        id: 'ng_contactUs_northAmerica',
        defaultMessage: 'North America',
    },
    ng_contactUs_tollFree: {
        id: 'identityServer.contactUs.tollFree',
        defaultMessage: 'Toll Free',
    },
    ng_contactUs_tollPhone: {
        id: 'ng_contactUs_tollPhone',
        defaultMessage: 'Toll Phone',
    },
    ng_contactUs_unitedKingdom: {
        id: 'identityServer.contactUs.unitedKingdom',
        defaultMessage: 'United Kingdom',
    },
    ng_contactUs_telephone: {
        id: 'identityServer.contactUs.telephone',
        defaultMessage: 'Telephone',
    },
    ng_contactUs_sendByPhone: {
        id: 'identityServer.contactUs.sendByPhone',
        defaultMessage: 'Send By Phone',
    },
    ng_contactUs_usDirect: {
        id: 'identityServer.contactUs.usDirect',
        defaultMessage: 'U.S. Direct',
    },
    ng_contactUs_phone: {
        id: 'ng_contactUs_phone',
        defaultMessage: 'Phone',
    },
    ng_contactUs_UK: {
        id: 'ng_contactUs_UK',
        defaultMessage: 'UK',
    },
});

export default contactUsTranslations;
