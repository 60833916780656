import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { fp as _ } from 'onsolve-ui-components';

import { FormattedErrorMessageText, NotificationBox } from 'components/Base';
import { errorTranslations } from 'translations';
import { errorTranslationService } from 'services';
import { ERROR_TYPE } from 'common/constants/enums';

const ErrorForm = ({ errors }) => {
    if (!Array.isArray(errors) && !!errors.Code) {
        const errorKey = errorTranslations[errors.Code] ? errors.Code : 'internalServerError';

        return <NotificationBox variant="danger" message={<FormattedMessage {...errorTranslations[errorKey]} />} />;
    }

    const errorKeys = Object.keys(errors);

    if (!errorKeys.length) {
        return false;
    }

    if (errorKeys.length === 2) {
        return (
            <NotificationBox
                variant="danger"
                message={<FormattedErrorMessageText error={errorTranslations.signInFormBothFieldsError} />}
            />
        );
    }

    if (errorKeys.length === 1 && errors.name === 'IncorrectUserNameOrPassword') {
        return (
            <NotificationBox
                variant="danger"
                message={<FormattedErrorMessageText error={errorTranslations.signInFormIncorrectUserNameOrPassword} />}
            />
        );
    }

    const [firstError] = errorKeys;
    let errorMessage = errorTranslationService.getErrorById(ERROR_TYPE.signIn, firstError);

    if (_.isEmpty(errorMessage)) {
        errorMessage = errorTranslations[errors[firstError]] || {};
    }

    return <NotificationBox variant="danger" message={<FormattedErrorMessageText error={errorMessage} />} />;
};

ErrorForm.propTypes = {
    errors: PropTypes.object,
};

export default injectIntl(ErrorForm);
