import { coreUnAuthTranslations as frt } from 'translations';

export const DEVICE_TYPE = {
    email: 1,
    sms: 2,
    voice: 3,
};

export const ERROR_CODE = {
    INVALID_NAMESPACE: '1013',
    INVALID_RETURN_URL: '1012',
    PASSWORD_CANNOT_BE_REUSED: 'PASSWORD_CANNOT_BE_REUSED',
    SECURITY_QUESTION_INVALID: 'securityQuestionInvalid',
    SECURITY_ANSWER_INVALID: 'securityAnswerInvalid',
    SECURITY_QUESTION_AND_ANSWER_INVALID: 'securityQuestionAndAnswerInvalid',
};

export const ERROR_TYPE = {
    common: 'identityServer.errors.common.',
    server: 'identityServer.errors.server.',
    signIn: 'identityServer.errors.signIn.',
    signup: 'identityServer.errors.signup.',
    forgotCredentials: 'identityServer.errors.forgotCredentials.',
    accountConfirmation: 'identityServer.errors.accountConfirmation.',
    contactMethod: 'identityServer.errors.contactMethod.',
    securityQuestions: 'identityServer.errors.securityQuestions.',
};

export const RESET_PASSWORD_STEPS_DESCRIPTION_LOOKUP = {
    securityQuestions: frt.ng_forgotCredentials_answerSecurityQuestions,
    resetPassword: frt.ng_forgotCredentials_ResetPassword_sub_title,
    success: frt.ng_forgotCredentials_ResetPassword_sub_title_success,
};

export const RESET_PASSWORD_STEPS_KEYS = {
    tokenVerification: 'tokenVerification',
    securityQuestions: 'securityQuestions',
    resetPassword: 'resetPassword',
    success: 'success',
};

export const FORGOT_TYPES = {
    FORGOT_PASSWORD: 'forgotPassword',
    FORGOT_USERNAME: 'forgotUsername',
};

export const CLIENT_IDS = {
    ONSOLVE_UI_USER: 'onsolve-ui-user',
    SELF_REGISTRATION_USER: 'self-registration-user',
    CR_MOBILE_USER: 'cr-mobile-user',
};

export const Brand = {
    ONSOLVE_X: 'onsolvex',
    MIR3: 'mir3',
    BAC: 'bac',
    CODERED: 'codered',
    WTP: 'wtp',
};

export const MFA_METHODS = {
    SMS: 1,
    APP: 2,
};
