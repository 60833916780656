import clientService from './api/clientService';
import { responseService } from './';

export const defaultContext = {
    customizations: {
        title: null,
        logoUrl: null,
        colorScheme: null,
        templateId: null,
        helpCenterUrl: null,
        passwordValidationRules: {},
        accessPinRequired: false,
        brand: null,
    },
    policy: {
        url: '',
        TranslatedLinkText: {},
        requirePolicyConfirmation: true,
        isAgreed: false,
    },
    extensions: {
        returnUrl: '',
        isTokenValid: false,
        allowForgotUsername: true,
        allowSignUp: true,
        resetPasswordUsername: undefined,
        userId: undefined,
        lockoutRules: {},
        securityQuestions: {},
        userFailedLoginAttempts: undefined,
        userIsLocked: false,
        deviceLabelMappings: {},
        isOrganizationAccessible: true,
    },
    errors: [],
    ssoOptions: {},
    features: {},
};

export async function getContext(queryParams) {
    const { ReturnUrl, returnUrl, token } = queryParams || {};

    if (ReturnUrl || returnUrl) {
        return await getContextByReturnUrl(ReturnUrl || returnUrl);
    } else if (token) {
        return await getContextByToken(token);
    }

    return defaultContext;
}

async function getContextByReturnUrl(returnUrl) {
    const response = await clientService.getClientContext({ returnUrl });

    return setClientContext(response);
}

async function getContextByToken(token) {
    const response = await clientService.getClientContextByToken({ token });

    return setClientContext(response);
}

function setClientContext(response) {
    if (response) {
        if (response.responseJson?.isSuccessful) {
            const responseContext = response.responseJson.result;
            const { ssoOptions = {} } = responseContext;
            let resultContext = {
                customizations: {},
                policy: {},
                extensions: {},
                features: {},
                ...ssoOptions,
            };

            resultContext.customizations.title = responseContext.title
                ? responseContext.title
                : defaultContext.customizations.title;
            resultContext.customizations.logoUrl = responseContext.logoUrl
                ? responseContext.logoUrl
                : defaultContext.customizations.logoUrl;
            resultContext.customizations.colorScheme = responseContext.colorScheme
                ? responseContext.colorScheme
                : defaultContext.customizations.colorScheme;
            resultContext.customizations.templateId = responseContext.templateId
                ? responseContext.templateId
                : defaultContext.customizations.templateId;
            resultContext.customizations.helpCenterUrl = responseContext.help
                ? responseContext.help.url
                : defaultContext.customizations.helpCenterUrl;
            resultContext.customizations.passwordValidationRules = responseContext.passwordValidationRules
                ? responseContext.passwordValidationRules
                : defaultContext.customizations.passwordValidationRules;
            resultContext.customizations.brand = responseContext.brand || defaultContext.customizations.brand;

            resultContext.policy = responseContext.policy ? responseContext.policy : defaultContext.policy;
            resultContext.policy.isAgreed =
                !resultContext.policy.url || !resultContext.policy.requirePolicyConfirmation;

            resultContext.extensions.returnUrl = responseContext.returnUrl
                ? responseContext.returnUrl
                : defaultContext.extensions.returnUrl;
            resultContext.extensions.isTokenValid = !!responseContext.isTokenValid;
            resultContext.extensions.allowForgotUsername = !(
                responseContext.allowForgotUsername === null || responseContext.allowForgotUsername === undefined
            )
                ? responseContext.allowForgotUsername
                : defaultContext.extensions.allowForgotUsername;
            resultContext.extensions.allowSignUp = !(
                responseContext.allowSignUp === null || responseContext.allowSignUp === undefined
            )
                ? responseContext.allowSignUp
                : defaultContext.extensions.allowSignUp;
            resultContext.extensions.resetPasswordUsername = responseContext.user
                ? responseContext.user.userName
                : undefined;
            resultContext.extensions.isOrganizationAccessible =
                responseContext.isOrganizationAccessible != null
                    ? responseContext.isOrganizationAccessible
                    : defaultContext.extensions.isOrganizationAccessible;
            resultContext.extensions.userId = responseContext.user?.id;
            resultContext.extensions.userIsLocked = responseContext.user?.isLocked || false;
            resultContext.extensions.userFailedLoginAttempts = responseContext.user?.failedLoginAttempts;
            resultContext.extensions.securityQuestions = responseContext.user?.securityQuestions || {};
            resultContext.extensions.lockoutRules = responseContext.lockoutRules || {};
            resultContext.extensions.deviceLabelMappings = responseContext.deviceLabelMappings || {};

            resultContext.customizations.accessPinRequired = responseContext.accessPinRequired
                ? responseContext.accessPinRequired
                : defaultContext.customizations.accessPinRequired;

            resultContext.features = responseContext.features || defaultContext.features;
            return { ...defaultContext, ...resultContext };
        } else if (response.responseJson?.errors) {
            return { ...defaultContext, errors: responseService.convertErrorMessages(response.responseJson.errors) };
        } else if (response.status === 500) {
            return {
                ...defaultContext,
                errors: [{ Code: response.status, Message: response.statusText }],
            };
        }
    }

    return defaultContext;
}
