import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './CustomPage.scss';

export const Left = ({ children }) => <div className="onsolve-custom-page__left-area col">{children}</div>;

Left.propTypes = {
    children: PropTypes.node,
};

export const Right = ({ children }) => <div className="onsolve-custom-page__right-area col">{children}</div>;

Right.propTypes = {
    children: PropTypes.node,
};

export const CustomPageBackground = ({ children, className }) => (
    <>
        <CustomPage className={classNames('onsolve-custom-page__background', className)}>
            <Left />
            <Right />
        </CustomPage>
        {children}
    </>
);

CustomPageBackground.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export const CustomPage = ({ children, className }) => (
    <div className={classNames('onsolve-custom-page row', className)}>{children}</div>
);

CustomPage.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};
