import { defineMessages } from 'react-intl';

const componentsTranslations = defineMessages({
    ng_components_pwdFieldHintTitle: {
        id: 'identityServer.common.password.fieldHintTitle',
        defaultMessage: 'Your password must have:',
    },
    ng_components_startsWith: {
        id: 'identityServer.common.startsWith',
        defaultMessage: 'Starts with',
    },
    ng_components_contains: {
        id: 'identityServer.common.contains',
        defaultMessage: 'Contains',
    },
});

export default componentsTranslations;
