import { defineMessages } from 'react-intl';

const disasterRecoveryTranslations = defineMessages({
    ng_signIn_drHeading: {
        id: 'ng_drHeading',
        defaultMessage: 'IMPORTANT NOTICE!',
    },
    ng_signIn_drEnvironmentText: {
        id: 'ng_drEnvironmentText',
        defaultMessage: `OnSolve is currently operating from our redundant environment. 
            The system will revert to the primary environment once the work underway in our primary environment is completed.`,
    },
    ng_signIn_drEnvironmentAvailableToUse: {
        id: 'ng_drEnvironmentAvailableToUse',
        defaultMessage: 'During this time, the platform is accessible and available for use, you ',
    },
    ng_signIn_may: {
        id: 'ng_may',
        defaultMessage: 'may',
    },
    ng_signIn_addEditOrImportContacts: {
        id: 'ng_drEnvironmentAvailableToUse',
        defaultMessage:
            ' add, edit or import contacts, however it is highly recommended that you do this only if necessary as this ',
    },
    ng_signIn_backToPrimary: {
        id: 'ng_backToPrimary',
        defaultMessage: 'work will not be transferred back to primary.',
    },
    ng_signIn_schedulesAlerts: {
        id: 'ng_schedulesAlerts',
        defaultMessage: ' Also, any scheduled alerts will not be launched.',
    },
    ng_signIn_drNote: {
        id: 'ng_signIn_drNote',
        defaultMessage: `Please note: DataSync, Weather & Events, and Custom Reporting will not be available while on the Secondary site. 
            If you need Alert Analytics, please contact `,
    },
    ng_signIn_drContactInfo: {
        id: 'ng_signIn_drContactInfo',
        defaultMessage: 'support@onsolve.com',
    },
    ng_signIn_drContactWithinFortyHours: {
        id: 'ng_signIn_drContactWithinFortyHours',
        defaultMessage: ' within 40 hours of using our redundant site, or this data will be lost.',
    },
    ng_signIn_drContactSupport: {
        id: 'ng_signIn_drContactSupport',
        defaultMessage: 'For questions or concerns, please contact OnSolve Support at:',
    },
    ng_signIn_drNorthAmericaContact: {
        id: 'ng_signIn_drNorthAmericaContact',
        defaultMessage: '+1-833-391-1911 (North America)',
    },
    ng_signIn_drEuropeContact: {
        id: 'ng_signIn_drEuropeContact',
        defaultMessage: '+44-0-330-999-3990 (Europe)',
    },
    ng_signIn_drTollPhone: {
        id: 'ng_signIn_drTollPhone',
        defaultMessage: '+1-858-724-1246 (Toll Phone)',
    },
    ng_signIn_drCustomerCommunity: {
        id: 'ng_signIn_drCustomerCommunity',
        defaultMessage: 'Customer Community ',
    },
    ng_signIn_drOnsolveCommunity: {
        id: 'ng_signIn_drOnsolveCommunity',
        defaultMessage: 'https://onsolvecomm.force.com/support/s/',
    },
});

export default disasterRecoveryTranslations;
