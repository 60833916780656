import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    ArrowDownIcon,
    InputFieldContainer,
    InputFieldControl,
    InputFieldGroup,
    InputFieldLabel,
    InputFieldToggle,
    Menu,
    MenuItem,
} from 'onsolve-ui-components';

import { KeyCodes } from 'common/constants';
import { DomUtils } from 'common/utility';

import { SuggestionList } from '../SuggestionList';
import CountryCodeSuggestionItem from './CountryCodeSuggestionItem';

import './CountryCodeDropdown.scss';

class CountryCodeDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isListOpen: false,
            isSuggestionListOpen: false,
            active: false,
            autoFocusSuggestionItem: false,
        };

        this.anchorEl = React.createRef();
        this.sizerRef = React.createRef();
    }

    get value() {
        const { value } = this.props;

        if (value != null) {
            return value;
        }

        return '';
    }

    handleToggle = () => {
        this.setState((prevState) => ({ isListOpen: !prevState.isListOpen, isSuggestionListOpen: false }));
    };

    handleChange = (e) => {
        const { onChange, countryCodeList } = this.props;
        const {
            target: { value },
        } = e;
        const formattedValue = value.toLowerCase();
        const isSuggestionListOpen = !!countryCodeList.find(
            (item) =>
                item.countryCode.includes(formattedValue) || item.countryName.toLowerCase().includes(formattedValue)
        );

        this.setState({ isListOpen: false, isSuggestionListOpen: isSuggestionListOpen }, () => {
            DomUtils.setFocus(this.anchorEl);
        });

        if (onChange) {
            onChange(value);
        }
    };

    handleMenuItemSelected = (item) => {
        return () => this.handleMenuItemEvent(item);
    };

    handleMenuItemKeyDown = (item) => {
        return (e) => {
            if (DomUtils.getCharCode(e) === KeyCodes.ENTER_KEY) {
                e.preventDefault();
                this.handleMenuItemEvent(item);
            }
        };
    };

    handleMenuItemEvent = (item) => {
        const { onSelect } = this.props;

        this.setState({ isListOpen: false });

        if (onSelect) {
            onSelect(item.countryCode);
        }
    };

    handleKeyDown = (e) => {
        const { DOWN, ENTER_KEY: ENTER } = KeyCodes;
        const { keyCode } = e;

        if (keyCode === DOWN) {
            this.setState({ autoFocusSuggestionItem: true });
        }

        if (keyCode === ENTER) {
            this.setState({ isListOpen: true, isSuggestionListOpen: false });
        }
    };

    handleSuggestionListClick = (e, itemValue, { item }) => {
        e.preventDefault();
        const { onSelect } = this.props;

        this.setState({ autoFocusSuggestionItem: false, isSuggestionListOpen: false });
        onSelect(item.countryCode);
    };

    handleSuggestionClose = () => {
        const { onChange } = this.props;

        this.setState({ isSuggestionListOpen: false }, () => {
            onChange(null);
        });
    };

    handleSuggestionListKeyDown = (e) => {
        e.preventDefault();

        const { ENTER_KEY: ENTER, ESC } = KeyCodes;

        if (e.keyCode === ENTER || e.keyCode === ESC) {
            this.setState({ autoFocusSuggestionItem: false, isSuggestionListOpen: false });
            DomUtils.setFocus(this.anchorEl);
        }
    };

    handleBlur = (e) => {
        const { isListOpen, isSuggestionListOpen } = this.state;
        const { onChange, countryCodeList } = this.props;
        const {
            target: { value },
        } = e;

        this.setState({ active: false });

        const includesValue = countryCodeList.map((item) => item.countryCode).includes(value);

        if (!isSuggestionListOpen && (!isListOpen || !includesValue)) {
            onChange(null);
        }
    };

    handleFocus = () => {
        this.setState({ active: true, autoFocusSuggestionItem: false });
    };

    handleClose = () => {
        this.setState({ isListOpen: false });
    };

    render() {
        const { placeholder, label, countryCodeList, variant } = this.props;
        const { active, isSuggestionListOpen, autoFocusSuggestionItem } = this.state;

        const items = countryCodeList.map((item) => {
            const { countryCode, countryName, id } = item;

            return (
                <MenuItem
                    id={id}
                    key={id}
                    role="option"
                    onClick={this.handleMenuItemSelected(item)}
                    onKeyDown={this.handleMenuItemKeyDown(item)}
                >
                    <div className="onsolve-country-code">
                        <div className="onsolve-country-code__result">
                            <div className="onsolve-country-code__result-code">{countryCode}</div>
                            <div className="onsolve-country-code__result-name">{countryName}</div>
                        </div>
                    </div>
                </MenuItem>
            );
        });

        const isListOpen = this.state.isListOpen && items.length > 0;
        const itemRender = ({ search, item }) => <CountryCodeSuggestionItem search={search} item={item} />;
        const placeholderClasses = classNames('onsolve-input-field__placeholder', {
            'onsolve-input-field__placeholder--sm': variant === 'outlined',
        });

        return (
            <Fragment>
                <InputFieldControl className={'onsolve-autocomplete'}>
                    <InputFieldGroup>
                        {label && <InputFieldLabel>{label}</InputFieldLabel>}
                        <InputFieldContainer variant={'standard'} active={active}>
                            {this.value.toString().length === 0 && (
                                <span
                                    className={classNames(
                                        'onsolve-country-code-input__placeholder',
                                        placeholderClasses
                                    )}
                                >
                                    {placeholder}
                                </span>
                            )}
                            <input
                                role="combobox"
                                aria-haspopup="true"
                                aria-autocomplete="both"
                                aria-owns={'country_code_listBox'}
                                aria-expanded={isListOpen}
                                aria-controls="country_code_listBox"
                                className={'onsolve-text-field__input'}
                                type="text"
                                value={this.value}
                                autoComplete="off"
                                ref={this.anchorEl}
                                onChange={this.handleChange}
                                onKeyDown={this.handleKeyDown}
                                onFocus={this.handleFocus}
                                onBlur={this.handleBlur}
                            />

                            <InputFieldToggle
                                className="onsolve-autocomplete__toggle"
                                icon={ArrowDownIcon}
                                onClick={this.handleToggle}
                            />
                        </InputFieldContainer>
                    </InputFieldGroup>
                </InputFieldControl>
                <Menu
                    id={this.listBoxId}
                    anchorEl={this.anchorEl.current}
                    open={isListOpen}
                    role="listbox"
                    onClose={this.handleClose}
                    className="onsolve-country-code-dropdown"
                >
                    {items}
                </Menu>
                <SuggestionList
                    autoFocus={autoFocusSuggestionItem}
                    itemRender={itemRender}
                    search={this.value}
                    anchorEl={this.anchorEl}
                    showSuggestionList={isSuggestionListOpen}
                    suggestions={countryCodeList}
                    field={['countryCode', 'countryName']}
                    dataItemKey={'countryName'}
                    onClick={this.handleSuggestionListClick}
                    onClose={this.handleSuggestionClose}
                    onKeyDown={this.handleSuggestionListKeyDown}
                    className={'onsolve-country-code-suggestion-list onsolve-country-code-dropdown'}
                />
            </Fragment>
        );
    }
}

CountryCodeDropdown.propTypes = {
    variant: PropTypes.oneOf(['default', 'outlined']).isRequired,
    onChange: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    countryCodeList: PropTypes.arrayOf(
        PropTypes.shape({
            countryCode: PropTypes.string,
            countryName: PropTypes.string,
            id: PropTypes.string,
        })
    ),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    value: PropTypes.string,
};

CountryCodeDropdown.defaultProps = {
    countryCodeList: [],
    variant: 'default',
};

export default CountryCodeDropdown;
