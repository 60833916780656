import settingsConfigurationService from './settingsConfigurationService';

const templateService = {
    get selfRegistrationTemplates() {
        const { selfRegistrationTemplates } = settingsConfigurationService.get();

        return selfRegistrationTemplates;
    },

    isSelfRegistrationTemplate: (templateId) => {
        if (!templateId || !templateService.selfRegistrationTemplates) {
            return false;
        }

        return !!templateService.selfRegistrationTemplates.find((x) => x.guid === templateId);
    },
};

export default templateService;
