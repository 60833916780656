import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputFieldMessage } from 'onsolve-ui-components';

import './style.scss';

export const CODE_LENGTH = new Array(6).fill(0);

export default class CodeVerification extends Component {
    inputRef = React.createRef();
    state = { focused: false };

    handleClick = () => {
        this.inputRef.current.focus();
    };

    handleFocus = () => {
        this.setState({ focused: true });
    };

    handleBlur = (e) => {
        this.setState({ focused: false });
        this.props.onBlur(e);
    };

    handleChange = (e) => {
        this.props.onChange(e);
    };

    handleKeyDown = (e) => {
        if (e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40) {
            e.preventDefault();
        }
    };

    render() {
        const { focused } = this.state;
        const { value, name, error, errorText } = this.props;
        const selectedIndex = value.length < CODE_LENGTH.length ? value.length : CODE_LENGTH.length - 1;

        return (
            <div className="onsolve-code-verification" onClick={this.handleClick}>
                <input
                    name={name}
                    value={value}
                    maxLength={CODE_LENGTH.length}
                    ref={this.inputRef}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onKeyDown={this.handleKeyDown}
                    className="onsolve-code-verification__input"
                    style={{
                        width: '50px',
                        top: '0px',
                        bottom: '0px',
                        left: `${selectedIndex * 50}px`,
                        color: 'transparent',
                    }}
                />
                <div className="onsolve-code-verification__input-container">
                    {CODE_LENGTH.map((v, index) => {
                        const selected = value.length === index;
                        const filled = value.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;

                        return (
                            <div
                                key={index}
                                className={`
                                    onsolve-code-verification__input-cell
                                    ${(selected || filled) && focused && 'onsolve-code-verification__selected-border'}
                                    ${filled && 'onsolve-code-verification__filled'}
                                `}
                            >
                                {value[index]}

                                {(selected || filled) && focused && (
                                    <div className="onsolve-code-verification__selected-item" />
                                )}
                            </div>
                        );
                    })}
                </div>
                <InputFieldMessage error={error} errorText={errorText} />
            </div>
        );
    }
}

CodeVerification.propTypes = {
    error: PropTypes.object,
    errorText: PropTypes.object,
    name: PropTypes.object,
    value: PropTypes.object,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};
