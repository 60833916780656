import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { EyeIcon, EyeStrikedIcon, TextField } from 'onsolve-ui-components';
import { injectIntl } from 'react-intl';
import { sharedTranslations } from 'translations';

const IconComponent = { password: EyeStrikedIcon, text: EyeIcon };

const PasswordTextField = (props) => {
    const [type, setType] = useState('password');
    const { intl } = props;
    const Icon = IconComponent[type];

    return (
        <TextField
            type={type}
            {...props}
            inputIcon={
                <Icon
                    color="primary"
                    className="onsolve-btn-icon"
                    onClick={() => setType(type === 'password' ? 'text' : 'password')}
                    aria-label={intl.formatMessage(sharedTranslations.ng_generic_showPassword)}
                />
            }
            autoComplete="off"
        />
    );
};

PasswordTextField.propTypes = {
    intl: PropTypes.object,
};

export default injectIntl(PasswordTextField);
