import React from 'react';
import PropTypes from 'prop-types';
import { CheckBox } from 'onsolve-ui-components';
import { injectIntl } from 'react-intl';

const CheckBoxBlock = ({
    showCheckBox,
    checkBoxChecked,
    checkBoxOnChange,
    checkBoxClass,
    checkBoxFillerClass,
    labelText,
    intl,
    labelLinkFormattedText,
}) => {
    return showCheckBox ? (
        <CheckBox
            checked={checkBoxChecked}
            onChange={checkBoxOnChange}
            className={checkBoxClass}
            aria-label={labelText ? intl.formatMessage(labelText) : labelLinkFormattedText}
        />
    ) : (
        <div className={checkBoxFillerClass} />
    );
};

CheckBoxBlock.propTypes = {
    checkBoxChecked: PropTypes.bool,
    checkBoxClass: PropTypes.string,
    checkBoxFillerClass: PropTypes.string,
    checkBoxOnChange: PropTypes.func,
    intl: PropTypes.object,
    labelLinkFormattedText: PropTypes.object,
    labelText: PropTypes.object,
    showCheckBox: PropTypes.bool,
};

CheckBoxBlock.defaultProps = {
    showCheckBox: true,
    checkBoxClass: 'onsolve-sign-up__terms-and-conditions-checkbox',
    checkBoxFillerClass: 'onsolve-sign-up__terms-and-conditions-checkbox-space-filler',
    labelLinkFormattedText: '',
};

export default injectIntl(CheckBoxBlock);
