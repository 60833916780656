import { defineMessages } from 'react-intl';

const selfRegistrationSignUpTranslations = defineMessages({
    ng_signInLink_alreadyHaveAnAccount: {
        id: 'identityServer.selfRegistration.signUp.alreadyHaveAnAccount',
        defaultMessage: 'Already have an Account?',
    },
    ng_signInLink_signInToYourAccount: {
        id: 'identityServer.selfRegistration.signUp.signInToYourAccount',
        defaultMessage: 'Sign in to your account to update and edit your information with',
    },
    ng_signInLink_signInButton: {
        id: 'identityServer.selfRegistration.signUp.button.signIn.button',
        defaultMessage: 'Sign in',
    },
    ng_signUpForm_createAnAccount: {
        id: 'identityServer.selfRegistration.signUp.createAnAccount',
        defaultMessage: 'Create an Account',
    },
    ng_signUpForm_completeField: {
        id: 'identityServer.selfRegistration.signUp.completeField',
        defaultMessage: 'Complete the fields below to start the registration process with',
    },
    ng_signUpForm_firstName: {
        id: 'identityServer.selfRegistration.signUp.firstName',
        defaultMessage: 'First Name',
    },
    ng_signUpForm_firstNamePlaceholder: {
        id: 'identityServer.selfRegistration.signUp.firstName.placeholder',
        defaultMessage: 'Enter first name',
    },
    ng_signUpForm_lastName: {
        id: 'identityServer.selfRegistration.signUp.lastName',
        defaultMessage: 'Last Name',
    },
    ng_signUpForm_lastNamePlaceholder: {
        id: 'identityServer.selfRegistration.signUp.lastName.placeholder',
        defaultMessage: 'Enter last name',
    },
    ng_signUpForm_termsAndConditionsLink: {
        id: 'identityServer.selfRegistration.signUp.termsAndConditions.link',
        defaultMessage: 'policy, terms and conditions, & the user agreement.',
    },
    ng_signUpForm_privacy: {
        id: 'identityServer.selfRegistration.signUp.privacy',
        defaultMessage: 'I have read, understand and agree to the',
    },
    ng_signUpForm_privacyLink: {
        id: 'identityServer.selfRegistration.signUp.privacy.link',
        defaultMessage: 'OnSolve privacy policy. ',
    },
    ng_signUpForm_wtpPrivacyLink: {
        id: 'identityServer.selfRegistration.signUp.privacy.link',
        defaultMessage: 'World Travel Protection privacy policy. ',
    },
    ng_signUpForm_termsAndConditions: {
        id: 'identityServer.selfRegistration.signUp.termsAndConditions',
        defaultMessage: 'Review the {title}',
    },
});

export default selfRegistrationSignUpTranslations;
