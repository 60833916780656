import {
    contactUsTranslations,
    coreUnAuthTranslations as frt,
    multiFactorAuthenticationTranslations,
    sharedTranslations,
} from 'translations';

import { Brand, ERROR_CODE, MFA_METHODS, RESET_PASSWORD_STEPS_KEYS } from './enums';

export const DEFAULT_LANGUAGE = {
    lcId: 'en_US',
    direction: 'LTR',
    translatedName: 'English',
    isoCode: 'en-US',
    cultureRegionCode: 'US',
    cultureDisplayName: 'English (United States)',
    cultureLanguageCode: 'en',
};

export const RESET_CREDENTIALS_ROUTES = {
    RESET_PASSWORD: '/reset-credentials/password',
    RESET_USERNAME: '/reset-credentials/username',
    SIGN_IN: '/signIn',
};

export const RESET_PASSWORD_FIELDS = {
    PASSWORD: 'password',
    CONFIRM_PASSWORD: 'confirmPassword',
};

export const CONTACT_INFO = {
    US_PHONE: '+1-833-391-1911',
    US_PHONE_DATA: 18333911911,
    US_EMAIL: 'support@onsolve.com',
    US_CUSTOMER_COMMUNITY: 'https://onsolvecomm.force.com/support/s/',
    UK_PHONE: '+44-0-330-999-3990',
    UK_PHONE_DATA: 4403309993990,
    UK_EMAIL: 'support@onsolve.com',
    UK_CUSTOMER_COMMUNITY: 'https://onsolvecomm.force.com/support/s/',
    TOLL_PHONE: '+1 858-724-1246',
    TOLL_PHONE_DATA: 18587241246,
    WTP_AUSTRALIA_PHONE: '+61 2 8907 5686',
    WTP_AUSTRALIA_PHONE_DATA: 61289075686,
    WTP_AUSTRALIA_EMAIL: 'travelassist@worldtravelprotection.com',
    WTP_NORTH_AMERICA_TOLL_FREE: '+1-800-667-2523',
    WTP_NORTH_AMERICA_TOLL_FREE_DATA: 18006672523,
    WTP_NORTH_AMERICA_PHONE: '+1-416-977-8305',
    WTP_NORTH_AMERICA_PHONE_DATA: 14169778305,
    WTP_NORTH_AMERICA_EMAIL: 'travelassist@worldtravelprotection.com',
    WTP_UK_PHONE: '+44-20-8156-2600',
    WTP_UK_PHONE_DATA: 442081562600,
    WTP_UK_EMAIL: 'travelassist@worldtravelprotection.com',
};

export const RESET_PASSWORD_STEPS_TITLES = {
    [RESET_PASSWORD_STEPS_KEYS.tokenVerification]: { title: frt.ng_forgotCredentials_tokenVerification },
    [RESET_PASSWORD_STEPS_KEYS.securityQuestions]: { title: frt.ng_forgotCredentials_securityQuestions },
    [RESET_PASSWORD_STEPS_KEYS.resetPassword]: { title: frt.ng_forgotCredentials_ResetPassword_short },
    [RESET_PASSWORD_STEPS_KEYS.success]: { title: frt.ng_common_success },
};

export const RESET_PASSWORD_STEPS_LOOKUP = {
    [RESET_PASSWORD_STEPS_KEYS.tokenVerification]: {
        initialStepData: {},
    },
    [RESET_PASSWORD_STEPS_KEYS.securityQuestions]: {
        initialStepData: {},
    },
    [RESET_PASSWORD_STEPS_KEYS.resetPassword]: {
        initialStepData: {},
    },
    [RESET_PASSWORD_STEPS_KEYS.success]: {
        initialStepData: {},
        completed: true,
    },
};

export const RESET_PASSWORD_STEPS = Object.keys(RESET_PASSWORD_STEPS_LOOKUP).map((id) => {
    const { initialStepData: stepData, completed } = RESET_PASSWORD_STEPS_LOOKUP[id];
    const { title } = RESET_PASSWORD_STEPS_TITLES[id];

    return {
        id,
        completed: !!completed,
        title,
        stepData,
    };
});

export const STEPS_ENUM = Object.keys(RESET_PASSWORD_STEPS_LOOKUP).reduce(
    (acc, curr, index) => ({ ...acc, [curr]: index }),
    {}
);

export const PASSWORD_MAX_LENGTH = 100;

export const ERROR_CODES_LOOKUP = {
    [ERROR_CODE.SECURITY_QUESTION_INVALID]: (errors) => errors.question,
    [ERROR_CODE.SECURITY_ANSWER_INVALID]: (errors) => errors.answer,
    [ERROR_CODE.SECURITY_QUESTION_AND_ANSWER_INVALID]: (errors) => errors.question && errors.answer,
};

export const SECURITY_QUESTION_MAX_LENGTH = 100;

export const SECURITY_QUESTIONS_TRANSLATION_PREFIX = 'ng_securityQuestions_';

export const CODE_RED_CUSTOMER_SUPPORT_INFO = [
    { contact: frt.ng_forgotCredentials_email, value: 'crmasupport@onsolve.com' },
    { contact: frt.ng_forgotCredentials_tollFee, value: '(866) 533-6935' },
];

export const DEFAULT_CUSTOMER_SUPPORT_INFO = [
    { contact: contactUsTranslations.ng_contactUs_phone, value: CONTACT_INFO.US_PHONE },
    { contact: contactUsTranslations.ng_contactUs_UK, value: CONTACT_INFO.UK_PHONE },
    { contact: sharedTranslations.ng_generic_email, value: CONTACT_INFO.US_EMAIL },
];

export const DEFAULT_SEND_BY_PHONE_INFO = [
    { contact: contactUsTranslations.ng_contactUs_tollFree, value: '+1-855-210-0473' },
    { contact: contactUsTranslations.ng_contactUs_usDirect, value: '+1-518-831-0898' },
];

export const BRANDS_CUSTOMER_SUPPORT_INFO = {
    [Brand.ONSOLVE_X]: DEFAULT_CUSTOMER_SUPPORT_INFO,
    [Brand.CODERED]: CODE_RED_CUSTOMER_SUPPORT_INFO,
};

export const MFA_METHODS_LOOKUP = [
    {
        id: MFA_METHODS.SMS,
        value: multiFactorAuthenticationTranslations.ng_MFA_devices_SMS,
    },
    {
        id: MFA_METHODS.APP,
        value: multiFactorAuthenticationTranslations.ng_MFA_devices_MSAuth,
    },
];

export const CODE_RED_PORTAL_TITLE = 'codeRedPortal';
