import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { EventsUtils } from 'onsolve-ui-components';

import { KeyCodes } from 'common/constants';

const handleFirstTab = (e) => {
    if (e.keyCode === KeyCodes.TAB) {
        document.body.classList.add('onsolve-tab-control');
        document.body.classList.add('user-is-tabbing');

        EventsUtils.unbind(window, 'keydown', handleFirstTab);
        EventsUtils.bind(window, 'mousedown', handleMouseDownOnce);
    }
};

const handleMouseDownOnce = () => {
    document.body.classList.remove('onsolve-tab-control');
    document.body.classList.remove('user-is-tabbing');

    EventsUtils.unbind(window, 'mousedown', handleMouseDownOnce);
    EventsUtils.bind(window, 'keydown', handleFirstTab);
};

const Layout = ({ children }) => {
    useEffect(() => {
        EventsUtils.bind(window, 'keydown', handleFirstTab);
        return () => EventsUtils.unbind(window, 'keydown', handleFirstTab);
    }, []);
    return <div className="container-fluid">{children}</div>;
};

Layout.propTypes = {
    children: PropTypes.object,
};

Layout.displayName = Layout.name;

export default Layout;
