import { useState, useEffect, useCallback } from 'react';

import { debounce } from 'common/utility';

const isClient = typeof window === 'object';

export const useWindowSize = () => {
    const getSize = useCallback(() => {
        return isClient ? window.innerWidth : undefined;
    }, []);

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        const eventDebounce = debounce(() => setWindowSize(getSize()), 50);

        if (!isClient) {
            return false;
        }

        window.addEventListener('resize', eventDebounce);
        return () => window.removeEventListener('resize', eventDebounce);
    }, [getSize]);

    return windowSize;
};
