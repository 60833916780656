import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CheckBoxBlock from './CheckBoxBlock';

const CheckboxSection = ({
    showSection,
    sectionClass,
    labelUrl,
    labelText,
    labelLinkFormattedText,
    labelLinkClass,
    labelClass,
    labelParameter,
    ...props
}) => {
    return (
        showSection && (
            <div className={sectionClass}>
                <CheckBoxBlock labelText={labelText} labelLinkFormattedText={labelLinkFormattedText} {...props} />
                <span className={labelClass}>
                    {labelText && <FormattedMessage {...labelText} values={{ title: labelParameter }} />}

                    {labelLinkFormattedText && (
                        <a target="_blank" rel="noopener noreferrer" href={labelUrl} className={labelLinkClass}>
                            {labelLinkFormattedText}
                        </a>
                    )}
                </span>
            </div>
        )
    );
};

CheckboxSection.propTypes = {
    checkBoxChecked: PropTypes.bool,
    checkBoxClass: PropTypes.string,
    checkBoxFillerClass: PropTypes.string,
    checkBoxOnChange: PropTypes.func,
    labelClass: PropTypes.string,
    labelLinkClass: PropTypes.string,
    labelLinkFormattedText: PropTypes.object,
    labelParameter: PropTypes.object,
    labelText: PropTypes.object,
    labelUrl: PropTypes.string,
    sectionClass: PropTypes.string,
    showCheckBox: PropTypes.bool,
    showSection: PropTypes.bool,
};

CheckboxSection.defaultProps = {
    sectionClass: 'onsolve-sign-up__terms-and-conditions-section',
    showSection: true,
    labelClass: 'onsolve-sign-up__terms-and-conditions-label text-overflow-break-word',
    labelLinkClass: 'onsolve-sign-up__terms-and-conditions-link',
    showCheckBox: true,
    checkBoxClass: 'onsolve-sign-up__terms-and-conditions-checkbox',
    checkBoxFillerClass: 'onsolve-sign-up__terms-and-conditions-checkbox-space-filler',
    labelLinkFormattedText: '',
};

export default CheckboxSection;
