import React, { useState, useContext } from 'react';

import { LanguageDropdown, LanguageModal } from '../LanguageSelection';
import { LanguageContext } from 'providers';
import { localeService, locationService } from 'services';
import { useWindowSize } from 'common/hooks';

import './style.scss';

const LanguageContainer = () => {
    const [isOpenLanguageDropdown, setOpenLanguageDropdown] = useState(false);
    const [isOpenLanguageModal, setOpenLanguageModal] = useState(false);
    const { language, setLanguage } = useContext(LanguageContext);

    const toggleLanguageDropdown = () => {
        setOpenLanguageDropdown(!isOpenLanguageDropdown);
    };

    const loadLanguageRequested = async (newLanguage) => {
        const properties = locationService.queryReturnUrlParams;

        properties.innerParams = {
            ...properties.innerParams,
            ui_lang: newLanguage.lcId,
        };
        locationService.SetReturnUrlParams(properties);

        const messages = await localeService.getTranslations(newLanguage);

        setLanguage(messages);
    };

    const afterLanguageChange = async (newLanguage) => {
        await loadLanguageRequested(newLanguage);
        setOpenLanguageDropdown(false);
    };

    const toggleLanguageModal = () => {
        setOpenLanguageModal(!isOpenLanguageModal);
    };

    const getWindowSize = useWindowSize();

    return (
        <>
            <div className="onsolve-lang-dropdown d-none d-md-block">
                <LanguageDropdown
                    isOpen={isOpenLanguageDropdown}
                    toggleDropdown={toggleLanguageDropdown}
                    afterLanguageChange={afterLanguageChange}
                    currentLanguage={language}
                    renderClose
                />
            </div>
            <div className="onsolve-lang-dropdown d-sm-block d-md-none">
                <LanguageDropdown
                    isOpen={false}
                    toggleDropdown={toggleLanguageModal}
                    afterLanguageChange={afterLanguageChange}
                    currentLanguage={language}
                    renderClose
                />
            </div>
            {getWindowSize < 768 && (
                <LanguageModal
                    isOpen={isOpenLanguageModal}
                    toggleModal={toggleLanguageModal}
                    afterLanguageChange={afterLanguageChange}
                    currentLanguage={language}
                />
            )}
        </>
    );
};

export default LanguageContainer;
