import PropTypes from 'prop-types';
import errorTranslations from 'translations/errorTranslations';

const errorTranslationService = {
    getErrorById: (type, messageId) => {
        if (!type || !messageId) {
            return {};
        }

        return getMessageById(`${type}${messageId}`);
    },

    getErrorByIdWithDefault: (type, messageId, message) => {
        if (!type || !messageId) {
            return { id: 'identityserver.free', defaultMessage: message };
        }

        const errorMessage = getMessageById(`${type}${messageId}`);

        if (errorMessage) {
            return errorMessage;
        }

        return { id: 'identityserver.free', defaultMessage: message };
    },

    getErrorByKey: (key) => {
        if (!key) {
            return {};
        }

        return errorTranslations[key];
    },

    getErrorByCode: (code) => {
        return errorTranslationService.getErrorByKey(code);
    },

    getErrorByCodeWithDefault: (code, message) => {
        if (!code) {
            return { id: 'identityserver.free', defaultMessage: message };
        }

        const errorMessage = errorTranslationService.getErrorByCode(code);

        if (errorMessage) {
            return errorMessage;
        }

        return { id: 'identityserver.free', defaultMessage: message };
    },
};

const getMessageById = (key) => {
    for (let messageKey in errorTranslations) {
        if (errorTranslations[messageKey].id.toLowerCase() === key.toLowerCase()) {
            return errorTranslations[messageKey];
        }
    }
    return {};
};

errorTranslationService.getErrorById.propTypes = {
    messageId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

errorTranslationService.getErrorByIdWithDefault.propTypes = {
    messageId: PropTypes.string.isRequired,
    message: PropTypes.string,
    type: PropTypes.string,
};

errorTranslationService.getErrorByKey.propTypes = {
    key: PropTypes.string.isRequired,
};

errorTranslationService.getErrorByCode.propTypes = {
    code: PropTypes.string.isRequired,
};

errorTranslationService.getErrorByCodeWithDefault.propTypes = {
    message: PropTypes.string.isRequired,
    code: PropTypes.string,
};

export default errorTranslationService;
