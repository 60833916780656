import React from 'react';

export const NotFoundImage = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="530" height="239" viewBox="0 0 530 239">
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <path fill="#FFF" fillRule="nonzero" stroke="#979797" d="M-4784.5 -1243.5H1769.5V950.5H-4784.5z" />
            <g transform="translate(-491 -255)">
                <path fill="#F9FAFC" d="M0 0H1280V1000H0z" />
                <g transform="translate(488 192)">
                    <path
                        fill="#F9FAFC"
                        fillRule="nonzero"
                        stroke="#D8D8D8"
                        d="M336.5 180.5v-8H317a3.5 3.5 0 00-3.5 3.5v1a3.5 3.5 0 003.5 3.5h19.5z"
                    />
                    <circle cx="319.5" cy="176.5" r="1.5" fill="#D8D8D8" fillRule="nonzero" />
                    <circle cx="330.5" cy="176.5" r="1.5" fill="#D8D8D8" fillRule="nonzero" />
                    <g fillRule="nonzero">
                        <path
                            fill="#3777BC"
                            fillOpacity="0.08"
                            d="M153.054 75l.005.5c0-32.309-28.44-58.5-63.523-58.5-34.87 0-63.177 25.874-63.52 57.912h-.775V75H26v145H8V75h1.058C10.686 33.312 46.078 0 89.5 0c43.422 0 78.814 33.312 80.442 75h1.117v145h-18V75h-.005z"
                            transform="translate(191 64)"
                        />
                        <path
                            fill="#FFF"
                            stroke="#D8D8D8"
                            d="M145.054 75l.005.5c0-32.309-28.44-58.5-63.523-58.5-34.87 0-63.177 25.874-63.52 57.912h-.775V75H18v145H0V75h1.058C2.686 33.312 38.078 0 81.5 0c43.422 0 78.814 33.312 80.442 75h1.117v145h-18V75h-.005z"
                            transform="translate(191 64)"
                        />
                    </g>
                    <g fontFamily="Lato-Regular, Lato" fontSize="288" fontWeight="normal">
                        <text fill="#3777BC" fillOpacity="0.08">
                            <tspan x="11.48" y="284">
                                4
                            </tspan>
                        </text>
                        <text fill="#FFF" stroke="#D8D8D8" strokeWidth="0.25">
                            <tspan x="0.48" y="284">
                                4
                            </tspan>
                        </text>
                    </g>
                    <path fillRule="nonzero" stroke="#D8D8D8" strokeLinecap="square" d="M196 141L196 283" />
                    <path fillRule="nonzero" stroke="#D8D8D8" strokeLinecap="square" d="M352 141L352 283" />
                    <path fillRule="nonzero" stroke="#D8D8D8" strokeLinecap="square" d="M198 141L198 239" />
                    <path fillRule="nonzero" stroke="#D8D8D8" strokeLinecap="square" d="M198 251L198 283" />
                    <circle cx="272.5" cy="156.5" r="4.5" fill="#F9FAFC" fillRule="nonzero" stroke="#D8D8D8" />
                    <circle cx="227.5" cy="194.5" r="3.5" fill="#F9FAFC" fillRule="nonzero" stroke="#D8D8D8" />
                    <circle cx="219" cy="209" r="6" fill="#F9FAFC" fillRule="nonzero" stroke="#D8D8D8" />
                    <circle cx="228" cy="195" r="1" fill="#D8D8D8" fillRule="nonzero" />
                    <rect
                        width="19"
                        height="3"
                        x="218.5"
                        y="207.5"
                        fill="#F9FAFC"
                        fillRule="nonzero"
                        stroke="#D8D8D8"
                        rx="1.5"
                    />
                    <path
                        fill="#FFF"
                        fillRule="nonzero"
                        stroke="#D8D8D8"
                        d="M209.5 146.697v11.942l126 41.669v-10.95l-126-42.66z"
                    />
                    <path
                        fill="#FFF"
                        fillRule="nonzero"
                        stroke="#D8D8D8"
                        d="M209.5 225.399v11.979l126-27.78v-12.971l-126 28.772z"
                    />
                    <path
                        fill="#FFF"
                        fillRule="nonzero"
                        stroke="#D8D8D8"
                        d="M209.5 232.622v11.973l126 26.788v-10.981l-126-27.78z"
                    />
                    <path fillRule="nonzero" stroke="#D8D8D8" strokeLinecap="square" d="M27 284L527 284" />
                    <path fillRule="nonzero" stroke="#D8D8D8" strokeLinecap="square" d="M160 294.167L190.5 294.167" />
                    <path fillRule="nonzero" stroke="#D8D8D8" strokeLinecap="square" d="M262 301.167L292.5 301.167" />
                    <path fillRule="nonzero" stroke="#D8D8D8" strokeLinecap="square" d="M207 294.167L381.5 294.167" />
                </g>
                <g transform="translate(488 192)">
                    <path
                        fill="#F9FAFC"
                        fillRule="nonzero"
                        stroke="#D8D8D8"
                        d="M336.5 180.5v-8H317a3.5 3.5 0 00-3.5 3.5v1a3.5 3.5 0 003.5 3.5h19.5z"
                    />
                    <circle cx="319.5" cy="176.5" r="1.5" fill="#D8D8D8" fillRule="nonzero" />
                    <circle cx="330.5" cy="176.5" r="1.5" fill="#D8D8D8" fillRule="nonzero" />
                    <g fillRule="nonzero">
                        <path
                            fill="#3777BC"
                            fillOpacity="0.08"
                            d="M153.054 75l.005.5c0-32.309-28.44-58.5-63.523-58.5-34.87 0-63.177 25.874-63.52 57.912h-.775V75H26v145H8V75h1.058C10.686 33.312 46.078 0 89.5 0c43.422 0 78.814 33.312 80.442 75h1.117v145h-18V75h-.005z"
                            transform="translate(191 64)"
                        />
                        <path
                            fill="#FFF"
                            stroke="#D8D8D8"
                            d="M145.054 75l.005.5c0-32.309-28.44-58.5-63.523-58.5-34.87 0-63.177 25.874-63.52 57.912h-.775V75H18v145H0V75h1.058C2.686 33.312 38.078 0 81.5 0c43.422 0 78.814 33.312 80.442 75h1.117v145h-18V75h-.005z"
                            transform="translate(191 64)"
                        />
                    </g>
                    <g fontFamily="Lato-Regular, Lato" fontSize="288" fontWeight="normal">
                        <text fill="#3777BC" fillOpacity="0.08">
                            <tspan x="11.48" y="284">
                                4
                            </tspan>
                        </text>
                        <text fill="#FFF" stroke="#D8D8D8" strokeWidth="0.25">
                            <tspan x="0.48" y="284">
                                4
                            </tspan>
                        </text>
                    </g>
                    <g fontFamily="Lato-Regular, Lato" fontSize="288" fontWeight="normal">
                        <text fill="#3777BC" fillOpacity="0.08" transform="translate(375)">
                            <tspan x="11.48" y="284">
                                4
                            </tspan>
                        </text>
                        <text fill="#FFF" stroke="#D8D8D8" strokeWidth="0.25" transform="translate(375)">
                            <tspan x="0.48" y="284">
                                4
                            </tspan>
                        </text>
                    </g>
                    <path fillRule="nonzero" stroke="#D8D8D8" strokeLinecap="square" d="M196 141L196 283" />
                    <path fillRule="nonzero" stroke="#D8D8D8" strokeLinecap="square" d="M352 141L352 283" />
                    <path fillRule="nonzero" stroke="#D8D8D8" strokeLinecap="square" d="M198 141L198 239" />
                    <path fillRule="nonzero" stroke="#D8D8D8" strokeLinecap="square" d="M198 251L198 283" />
                    <circle cx="272.5" cy="156.5" r="4.5" fill="#F9FAFC" fillRule="nonzero" stroke="#D8D8D8" />
                    <circle cx="227.5" cy="194.5" r="3.5" fill="#F9FAFC" fillRule="nonzero" stroke="#D8D8D8" />
                    <circle cx="219" cy="209" r="6" fill="#F9FAFC" fillRule="nonzero" stroke="#D8D8D8" />
                    <circle cx="228" cy="195" r="1" fill="#D8D8D8" fillRule="nonzero" />
                    <rect
                        width="19"
                        height="3"
                        x="218.5"
                        y="207.5"
                        fill="#F9FAFC"
                        fillRule="nonzero"
                        stroke="#D8D8D8"
                        rx="1.5"
                    />
                    <path
                        fill="#FFF"
                        fillRule="nonzero"
                        stroke="#D8D8D8"
                        d="M209.5 146.697v11.942l126 41.669v-10.95l-126-42.66z"
                    />
                    <path
                        fill="#FFF"
                        fillRule="nonzero"
                        stroke="#D8D8D8"
                        d="M209.5 225.399v11.979l126-27.78v-12.971l-126 28.772z"
                    />
                    <path
                        fill="#FFF"
                        fillRule="nonzero"
                        stroke="#D8D8D8"
                        d="M209.5 232.622v11.973l126 26.788v-10.981l-126-27.78z"
                    />
                    <path fillRule="nonzero" stroke="#D8D8D8" strokeLinecap="square" d="M27 284L527 284" />
                    <path fillRule="nonzero" stroke="#D8D8D8" strokeLinecap="square" d="M160 294.167L190.5 294.167" />
                    <path fillRule="nonzero" stroke="#D8D8D8" strokeLinecap="square" d="M262 301.167L292.5 301.167" />
                    <path fillRule="nonzero" stroke="#D8D8D8" strokeLinecap="square" d="M207 294.167L381.5 294.167" />
                </g>
            </g>
        </g>
    </svg>
);

export default React.memo(NotFoundImage);
