import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormattedMessage } from 'react-intl';

import { settingsConfigurationService, localeService } from 'services';
import { errorTranslations } from 'translations';

export default class Recaptcha extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            shouldReset: false,
        };

        this.component = React.createRef();
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    static getDerivedStateFromProps(nextProps) {
        return { shouldReset: nextProps.reset };
    }

    componentDidUpdate() {
        if (this.state.shouldReset) {
            this.component.current.reset();
        }
    }

    render() {
        const { onChange, onExpired, className } = this.props;
        const { hasError } = this.state;

        const { googleRecaptchaKey } = settingsConfigurationService.get();
        const { lang } = localeService;

        if (hasError) {
            return <FormattedMessage {...errorTranslations.recaptchaError} />;
        }

        return (
            <ReCAPTCHA
                sitekey={googleRecaptchaKey}
                className={className}
                onChange={onChange}
                onExpired={onExpired}
                ref={this.component}
                hl={lang.cultureLanguageCode}
            />
        );
    }
}

Recaptcha.propTypes = {
    className: PropTypes.object,
    reset: PropTypes.object,
    onChange: PropTypes.func,
    onExpired: PropTypes.func,
};
