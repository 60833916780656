import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { IconButton, CloseIcon } from 'onsolve-ui-components';
import { sharedTranslations } from 'translations';

import './CenterPanel.scss';

const CenterPanel = ({ children, onClose, className, contentClassName, intl }) => (
    <div className={classNames('onsolve-center-panel', className)}>
        {onClose && (
            <IconButton
                className="onsolve-center-panel__close-icon"
                onClick={onClose}
                size="xs"
                aria-label={intl.formatMessage(sharedTranslations.ng_generic_close)}
            >
                <CloseIcon color="primary" size="md" />
            </IconButton>
        )}

        <div className={classNames('onsolve-center-panel__content', contentClassName)}>{children}</div>
    </div>
);

CenterPanel.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    intl: PropTypes.object,
    onClose: PropTypes.func,
};

export default injectIntl(CenterPanel);
