import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { errorTranslations } from 'translations';

import { Toastr } from 'onsolve-ui-components';

const ErrorPopup = ({ popoverIsOpen, onClose, messageCode }) => {
    const popupMessage = messageCode ? (
        <FormattedMessage {...errorTranslations[messageCode]} />
    ) : (
        <FormattedMessage {...errorTranslations.popupErrorMessage} />
    );

    return (
        <>
            <Toastr
                variant="danger"
                position="top-right"
                isAlwaysVisible={true}
                content={popupMessage}
                isVisible={popoverIsOpen}
                onClose={onClose}
            />
        </>
    );
};

ErrorPopup.propTypes = {
    messageCode: PropTypes.string,
    popoverIsOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

export default ErrorPopup;
