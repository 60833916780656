import React from 'react';
import PropTypes from 'prop-types';
import { Highlighter } from 'onsolve-ui-components';

const CountryCodeSuggestionItem = ({ search, item }) => {
    return (
        <div className="onsolve-country-code">
            <div className="onsolve-country-code__result">
                <div className="onsolve-country-code__result-code">
                    <Highlighter search={search}>{item.countryCode}</Highlighter>
                </div>
                <div className="onsolve-country-code__result-name">
                    <Highlighter search={search}>{item.countryName}</Highlighter>
                </div>
            </div>
        </div>
    );
};

CountryCodeSuggestionItem.propTypes = {
    item: PropTypes.shape({
        countryCode: PropTypes.string.isRequired,
        countryName: PropTypes.string.isRequired,
    }).isRequired,
    search: PropTypes.string.isRequired,
};

export default CountryCodeSuggestionItem;
