import PropTypes from 'prop-types';

import errorTranslationService from './errorTranslationService';

const responseService = {
    convertErrorMessages: (response) => {
        if (response && response?.responseJson?.errors) {
            response.responseJson.errorMessages = [];

            for (let i in response.responseJson.errors) {
                let errorMessage = errorTranslationService.getErrorByCodeWithDefault(
                    response.responseJson.errors[i].Code,
                    response.responseJson.errors[i].Message
                );

                response.responseJson.errorMessages.push(errorMessage);
            }

            response.responseJson.status = response.status;
            response.responseJson.errorEnabled = true;
        }

        return response;
    },

    expandError: (response, message) => {
        if (response && response?.responseJson) {
            response.responseJson.errorMessages = [];
            response.responseJson.errorMessages.push(message);
            response.responseJson.errorEnabled = true;
            response.responseJson.status = response.status;
        }

        return response;
    },

    expandErrorByCode: (response, code) => {
        const message = errorTranslationService.getErrorByCode(code);

        return responseService.expandError(response, message);
    },
};

responseService.convertErrorMessages.propTypes = {
    response: PropTypes.object.isRequired,
};

responseService.expandError.propTypes = {
    message: PropTypes.string.isRequired,
    response: PropTypes.object.isRequired,
};

responseService.expandErrorByCode.propTypes = {
    code: PropTypes.string.isRequired,
    response: PropTypes.object.isRequired,
};

export default responseService;
