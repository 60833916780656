import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { locationService } from 'services';
import { errorTranslations } from 'translations';
import { NotificationBox } from 'components/Base';

import './style.scss';

const ClientErrorForm = ({ showNamespaceError }) => {
    if (showNamespaceError) {
        return (
            <NotificationBox
                variant="danger"
                message={<FormattedMessage {...errorTranslations.namespaceInvalid} />}
                className="client-error"
            />
        );
    }

    const clientError = {
        hasError: !!locationService.queryReturnUrlParams.innerParams?.client_error,
        Code: locationService.queryReturnUrlParams.innerParams?.client_error,
    };

    if (!clientError.hasError) {
        return false;
    }

    const message = clientError.hasError ? (
        <FormattedMessage {...errorTranslations[clientError.Code]} />
    ) : (
        <FormattedMessage {...errorTranslations.popupErrorMessage} />
    );

    return <NotificationBox variant="danger" message={message} className="client-error" />;
};

ClientErrorForm.propTypes = {
    showNamespaceError: PropTypes.bool,
};

export default injectIntl(ClientErrorForm);
