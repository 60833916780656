import axios from 'axios';

import settingsConfigurationService from './settingsConfigurationService';
import locationService from './locationService';

const removeTrailingSlash = (str) => str.replace(/\/+$/, '');

const namespaceService = {
    validateNamespace(namespace) {
        const { namespaceApiBaseUrl } = settingsConfigurationService.get();

        return axios.get(`${namespaceApiBaseUrl}api/v2/Authority/${namespace}`);
    },
    getOrigin() {
        const { externalDomainName } = settingsConfigurationService.get();
        const origin = locationService.queryReturnUrlParams?.innerParams?.origin;

        if (origin) {
            const url = new URL(origin);

            return {
                protocol: url.protocol,
                host: removeTrailingSlash(url.host),
            };
        }

        return {
            protocol: 'https:',
            host: removeTrailingSlash(externalDomainName),
        };
    },
};

export default namespaceService;
