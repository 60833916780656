import queryString from 'query-string';
import { fp as _ } from 'onsolve-ui-components';

const locationService = {
    get queryString() {
        return window.location.search;
    },

    get queryStringParams() {
        return queryString.parse(window.location.search);
    },

    get queryReturnUrlParams() {
        const params = queryString.parse(window.location.search);

        if (params.ReturnUrl) {
            const callback = params.ReturnUrl.substring(0, params.ReturnUrl.indexOf('?') + 1);
            const innerParams = params.ReturnUrl.substring(params.ReturnUrl.indexOf('?') + 1, params.ReturnUrl.length);

            return {
                callback: callback,
                innerParams: queryString.parse(innerParams),
            };
        }

        return {};
    },

    getUrlOrigin: (query = locationService.queryString) => {
        const decodedQuery = decodeURIComponent(query);
        const params = queryString.parse(decodedQuery);

        if (!_.isEmpty(params)) {
            return params.origin || locationService.getUrlOrigin(params.returnUrl || '');
        }

        return '';
    },

    getQueryParamsFromOrigin: () => {
        const decodedQuery = decodeURIComponent(locationService.getUrlOrigin());

        return queryString.parseUrl(decodedQuery).query;
    },

    SetReturnUrlParams: ({ callback, innerParams }) => {
        const params = queryString.parse(window.location.search);

        params.ReturnUrl = callback + queryString.stringify(innerParams);

        let newUrl =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname +
            '?' +
            queryString.stringify(params);

        window.history.pushState({ path: newUrl }, '', newUrl);
    },
};

export default locationService;
