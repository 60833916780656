import apiService from './apiService';

const clientService = {
    getClientContext: async (params) => {
        return await apiService.get(`/api/v1/ClientContext`, params);
    },

    getClientContextByToken: async (params) => {
        return await apiService.get(`/api/v1/ClientContext/ByToken`, params);
    },
};

export default clientService;
