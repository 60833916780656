import settingsConfigurationService from './settingsConfigurationService';

const getFeature = (name) => {
    if (!name) {
        return true;
    }

    const { featureFlags = {} } = settingsConfigurationService.get();

    return typeof featureFlags[name] === 'undefined' || featureFlags[name];
};

export default {
    getFeature,
};
