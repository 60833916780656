import React from 'react';

const UnauthorizedImage = () => (
    <svg width="400" height="370">
        <defs>
            <path id="a" d="M0 0h267v176H0z" />
            <path d="M2 0h43a2 2 0 0 1 2 2v48H0V2a2 2 0 0 1 2-2z" id="c" />
            <radialGradient
                cx="50%"
                cy="0%"
                fx="50%"
                fy="0%"
                r="100%"
                gradientTransform="matrix(0 1 -.95962 0 .5 -.5)"
                id="d"
            >
                <stop stopColor="#FFFEF1" offset="0%" />
                <stop stopColor="#FFFCDF" offset="100%" />
            </radialGradient>
            <radialGradient
                cx="50%"
                cy="0%"
                fx="50%"
                fy="0%"
                r="100%"
                gradientTransform="matrix(0 1 -.30882 0 .5 -.5)"
                id="e"
            >
                <stop stopColor="#FFFEF1" offset="0%" />
                <stop stopColor="#FFFCDF" offset="100%" />
            </radialGradient>
            <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="f">
                <stop stopColor="#C4C070" offset="0%" />
                <stop stopColor="#F6F58E" offset="100%" />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
            <path stroke="#979797" fill="transparent" fillRule="nonzero" d="M-574.5-1067.5h4554v2194h-4554z" />
            <g fillRule="nonzero" stroke="#F2F2F2" strokeWidth="4">
                <path
                    d="M59.85 306.763c-4.011 10.678-9.589 17.297-15.756 17.297-12.214 0-22.116-25.967-22.116-58 0-29.648 8.482-54.1 19.43-57.576-5.551-11.286-3.201-32.242 7.009-53.176 7.876-16.148 18.642-28.62 28.804-34.659-11.43-21.046-1.19-50.6 24.112-67.666 26.557-17.913 59.65-15.288 73.916 5.862 5.349 7.93 7.291 17.34 6.23 26.948 13.426 2.537 24.454 8.835 31.28 18.955 10.099 14.972 8.996 35.332-.933 55.75a88.64 88.64 0 0 1 14.517 16.646c24.398 36.172 18.22 83.004-13.8 104.602a62.52 62.52 0 0 1-17.868 8.313c-6.716 19.136-20.74 35.403-40.375 44.146-33.882 15.085-73.233 2.761-94.45-27.442zm46.665 32.297l14.772 2.605-4.515 25.605L102 364.665l4.515-25.605z"
                    fill="#FFF"
                />
                <path
                    d="M144 80l-30.5 171.5M109.5 114.5l20 35M127.5 181.5l51-49M113.5 251.5l-47-61M154 96l-15 15"
                    strokeLinecap="square"
                />
            </g>
            <g transform="translate(128 191)">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a" />
                </mask>
                <path
                    d="M156.935 183.906a81.05 81.05 0 0 1-8.57 1.503C139.978 216.33 112.546 239 80 239c-39.212 0-71-32.907-71-73.5 0-33.488 21.633-61.744 51.222-70.61C66.821 58.577 99.357 31 138.5 31c40.519 0 73.958 29.55 78.877 67.749C238.633 106.104 254 127.389 254 152.5c0 31.204-23.729 56.5-53 56.5-18.369 0-34.555-9.962-44.065-25.094z"
                    stroke="#F2F2F2"
                    strokeWidth="2"
                    fill="#F9F9F9"
                    fillRule="nonzero"
                    mask="url(#b)"
                />
            </g>
            <g fillRule="nonzero">
                <g transform="translate(98 252)" stroke="#212121">
                    <rect strokeWidth="3" fill="#D8D8D8" x="9.5" y="1.5" width="9" height="113" rx="1" />
                    <path d="M13 106v6" strokeWidth="2" strokeLinecap="square" />
                    <path strokeWidth="2" fill="#D8D8D8" d="M1 73h26v6H1z" />
                    <path strokeWidth="2" fill="#D8D8D8" d="M22 73h5v6h-5zM1 73h5v6H1z" />
                </g>
                <g transform="translate(280 252)" stroke="#212121">
                    <rect strokeWidth="3" fill="#D8D8D8" x="9.5" y="1.5" width="9" height="113" rx="1" />
                    <path d="M13 106v6" strokeWidth="2" strokeLinecap="square" />
                    <path strokeWidth="2" fill="#D8D8D8" d="M1 73h26v6H1z" />
                    <path strokeWidth="2" fill="#D8D8D8" d="M22 73h5v6h-5zM1 73h5v6H1z" />
                </g>
                <g transform="translate(89 261)">
                    <path
                        d="M154.588 1.5H1.5v17h225v-17h-65.248c-.457 1.707-1.688 3-3.332 3s-2.875-1.293-3.332-3zM1.5 18.5h225v17H1.5zM1.5 54.5h225v9H1.5z"
                        stroke="#2F2F2F"
                        strokeWidth="3"
                        fill="#A17F66"
                    />
                    <circle stroke="#2B2D2F" strokeWidth="3" fill="#FFF" cx="23" cy="10" r="3" />
                    <circle stroke="#2B2D2F" strokeWidth="3" fill="#FFF" cx="205" cy="10" r="3" />
                    <circle stroke="#2B2D2F" strokeWidth="3" fill="#FFF" cx="23" cy="27" r="3" />
                    <circle stroke="#2B2D2F" strokeWidth="3" fill="#FFF" cx="205" cy="27" r="3" />
                    <path d="M5 27h2" stroke="#64554A" strokeWidth="4" strokeLinecap="square" />
                    <path
                        d="M4 31h8M145 27h16M218 15h6M222 23h2"
                        stroke="#4B423C"
                        strokeWidth="2"
                        strokeLinecap="square"
                    />
                    <g strokeLinecap="square">
                        <path d="M60 10h28" stroke="#64554A" strokeWidth="3" />
                        <path d="M68 6h6M44 6h16" stroke="#4B423C" strokeWidth="2" />
                    </g>
                </g>
            </g>
            <g fillRule="nonzero">
                <g transform="translate(23 313)">
                    <path
                        stroke="#141414"
                        strokeWidth="2"
                        d="M1 49h45V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v47z"
                        strokeLinejoin="square"
                        fill="#CBCBCB"
                        fillRule="evenodd"
                    />
                    <use stroke="#A7A7A7" xlinkHref="#c" />
                </g>
                <g strokeLinecap="square">
                    <path d="M26 316v44" stroke="#474747" strokeWidth="2" />
                    <path d="M28 316v44" stroke="#9B9B9B" strokeWidth="3" />
                    <path d="M36 315v45" stroke="#232323" strokeWidth="2" />
                    <path d="M39 316v44" stroke="#383838" strokeWidth="2" />
                    <path d="M31 316v44" stroke="#595959" strokeWidth="3" />
                </g>
                <g strokeLinecap="square">
                    <path d="M67 316v44" stroke="#474747" strokeWidth="2" />
                    <path d="M65 316v44" stroke="#9B9B9B" strokeWidth="3" />
                    <path d="M57 315v45" stroke="#232323" strokeWidth="2" />
                    <path d="M56 316v44" stroke="#383838" strokeWidth="2" />
                    <path d="M52 316v44" stroke="#707070" strokeWidth="2" />
                    <path d="M46 316v44" stroke="#7C7C7C" strokeWidth="2" />
                    <path d="M62 316v44" stroke="#595959" strokeWidth="3" />
                </g>
                <path fill="#333" d="M26 362h4v6h-4zM35 362h2v6h-2z" />
                <g fill="#333">
                    <path d="M62 362h4v6h-4zM55 362h2v6h-2z" />
                </g>
            </g>
            <g transform="translate(327 1)" fillRule="nonzero">
                <g fill="#DFDFDF" stroke="#303030" strokeWidth="2">
                    <path d="M1 366h35v-6a3 3 0 0 0-3-3H4a3 3 0 0 0-3 3v6zM7 356h23v-3a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v3z" />
                    <path d="M1 366h10v-9H4a3 3 0 0 0-3 3v6zM36 366H26v-9h7a3 3 0 0 1 3 3v6z" />
                </g>
                <path stroke="#232323" strokeWidth="2" fill="#D8D8D8" d="M10 312h18v38H10z" />
                <path stroke="#232323" strokeWidth="2" fill="silver" d="M14 194h10v104H14z" />
                <path stroke="#232323" strokeWidth="2" fill="#BABABA" d="M16 75h6v106h-6z" />
                <g strokeLinecap="square" strokeWidth="2">
                    <path d="M13 314v28M13 345v3" stroke="#737373" />
                    <path d="M15 314v28M15 345v3" stroke="#626262" />
                </g>
                <g strokeLinecap="square" strokeWidth="2">
                    <path d="M18 298v-84M18 205v-9" stroke="#737373" />
                    <path d="M20 298v-84M20 205v-9" stroke="#626262" />
                </g>
                <g strokeLinecap="square" strokeWidth="2">
                    <path d="M23 348v-27M23 317v-3" stroke="#737373" />
                    <path d="M25 348v-27M25 317v-3" stroke="#626262" />
                </g>
                <rect stroke="#262626" strokeWidth="2" fill="#D8D8D8" x="7" y="305" width="24" height="6" rx="3" />
                <rect stroke="#262626" strokeWidth="2" fill="#D8D8D8" x="10" y="187" width="18" height="6" rx="3" />
                <path
                    d="M11 304h16v-2a3 3 0 0 0-3-3H14a3 3 0 0 0-3 3v2zM15 186h8v-2a3 3 0 0 0-3-3h-2a3 3 0 0 0-3 3v2z"
                    stroke="#262626"
                    strokeWidth="2"
                    fill="#D8D8D8"
                />
                <g transform="matrix(1 0 0 -1 11 76)" stroke="#262626" strokeWidth="2">
                    <rect fill="#D8D8D8" x="1" y="7" width="14" height="6" rx="3" />
                    <path d="M4 6h8V4a3 3 0 0 0-3-3H7a3 3 0 0 0-3 3v2z" fill="#BABABA" />
                </g>
                <path d="M17.5 76.5v103" stroke="#3F3F3F" strokeLinecap="square" />
                <path
                    d="M25.455 56H27l-.912-1.41-.633 1.41zm-9.837 6h-1.004a.414.414 0 0 1 .13.806l1.095-.365-.221-.441zm-3-6l-.724-1.447L11 56h1.618z"
                    stroke="#262626"
                    strokeWidth="2"
                    fill="#D8D8D8"
                />
                <path
                    d="M3.317 24l8.45 30.263c4.964.2 8.713.318 11.247.353 1.316.018 2.3.014 2.945-.013l.173-.008c.614-2.983 3.391-12.906 8.536-30.595H3.318z"
                    stroke="#262626"
                    strokeWidth="2"
                    fill="url(#d)"
                />
                <path
                    d="M4.192 13l7.27 8.379c5.106.067 8.954.106 11.542.117 1.328.006 2.323.005 2.983-.004.18-.002.336-.005.464-.009.844-1.25 2.982-3.697 7.298-8.483H4.192zm22.423 8.478c.03 0 .058-.002.083-.003-.03-.008-.055-.01-.083.003z"
                    stroke="#262626"
                    strokeWidth="2"
                    fill="url(#e)"
                    transform="matrix(1 0 0 -1 0 34.5)"
                />
                <rect stroke="#2D2D2D" fill="#383838" x="7.5" y="53.5" width="23" height="2" rx="1" />
                <rect stroke="#2D2D2D" fill="#383838" x=".5" y="21.5" width="37" height="2" rx="1" />
                <path d="M19.5 57.5v3" stroke="#262626" strokeLinecap="square" />
                <path d="M18.5 57.5v3" stroke="#575757" strokeLinecap="square" />
                <path d="M11 25l5 28M27 25l-5 28" stroke="#383838" strokeWidth="2" strokeLinecap="square" />
                <path d="M11.5 20.5L14 14M24 14l2.5 6.5" stroke="#262626" strokeWidth="2" strokeLinecap="square" />
                <path
                    d="M15 12h8v-1a3 3 0 0 0-3-3h-2a3 3 0 0 0-3 3v1z"
                    stroke="#262626"
                    strokeWidth="2"
                    fill="#D8D8D8"
                />
                <circle stroke="#262626" strokeWidth="2" fill="#FFF" cx="19" cy="4" r="4" />
            </g>
            <g fill="#2A2A2A" fillRule="nonzero" stroke="#2A2A2A">
                <path d="M.5 367.5h10v1H.5zM16.5 367.5h224v1h-224zM246.5 367.5h3v1h-3zM382.5 367.5h3v1h-3zM255.5 367.5h121v1h-121z" />
            </g>
            <g fillRule="nonzero" stroke="#35332E" strokeWidth="2">
                <path
                    d="M12 14.116c1.917 0 5.37-2.38 8-5.5 1.917-2.274 3.903-4.792 5-6.5 2-3.116.792-.506-2.552.672C21.09 3.266 19.524 3.738 18 4c-1.237.213-5-.116-8-1-3.045-.898-4 2.578-4 5.616 0 3.037 2.686 5.5 6 5.5z"
                    fill="url(#f)"
                    transform="translate(373 341)"
                />
                <path d="M373.5 348.25h16" strokeLinecap="square" />
            </g>
            <g fillRule="nonzero" stroke="#35332E" strokeWidth="2">
                <path
                    d="M12 14.116c1.917 0 5.37-2.38 8-5.5 1.917-2.274 3.903-4.792 5-6.5 2-3.116.792-.506-2.552.672C21.09 3.266 19.524 3.738 18 4c-1.237.213-5-.116-8-1-3.045-.898-4 2.578-4 5.616 0 3.037 2.686 5.5 6 5.5z"
                    fill="url(#f)"
                    transform="scale(1 -1) rotate(-26 -344.815 -672.954)"
                />
                <path d="M263.847 212.735l14.38 7.014" strokeLinecap="square" />
            </g>
            <g fillRule="nonzero" stroke="#35332E" strokeWidth="2">
                <path
                    d="M12 14.116c1.917 0 5.37-2.38 8-5.5 1.917-2.274 3.903-4.792 5-6.5 2-3.116.792-.506-2.552.672C21.09 3.266 19.524 3.738 18 4c-1.237.213-5-.116-8-1-3.045-.898-4 2.578-4 5.616 0 3.037 2.686 5.5 6 5.5z"
                    fill="url(#f)"
                    transform="rotate(-26 544.234 -720.911)"
                />
                <path d="M374.847 172.144l14.38-7.014" strokeLinecap="square" />
            </g>
        </g>
    </svg>
);

export default UnauthorizedImage;
