import React from 'react';

const ForbiddenImage = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="540" height="239" viewBox="0 0 540 239">
        <g transform="translate(-5 -63)" fill="none" fillRule="evenodd">
            <path
                d="M336.5 180.5v-8H317a3.5 3.5 0 00-3.5 3.5v1a3.5 3.5 0 003.5 3.5h19.5z"
                stroke="#D8D8D8"
                fill="#F9FAFC"
                fillRule="nonzero"
            />
            <circle fill="#D8D8D8" fillRule="nonzero" cx="319.5" cy="176.5" r="1.5" />
            <circle fill="#D8D8D8" fillRule="nonzero" cx="330.5" cy="176.5" r="1.5" />
            <g fillRule="nonzero">
                <path
                    d="M344.054 139l.005.5c0-32.309-28.44-58.5-63.523-58.5-34.87 0-63.177 25.874-63.52 57.912h-.775V139H217v145h-18V139h1.058c1.628-41.688 37.02-75 80.442-75 43.422 0 78.814 33.312 80.442 75h1.117v145h-18V139h-.005z"
                    fill="#DAE7F2"
                />
                <path
                    d="M336.054 139l.005.5c0-32.309-28.44-58.5-63.523-58.5-34.87 0-63.177 25.874-63.52 57.912h-.775V139H209v145h-18V139h1.058c1.628-41.688 37.02-75 80.442-75 43.422 0 78.814 33.312 80.442 75h1.117v145h-18V139h-.005z"
                    stroke="#D8D8D8"
                    fill="#FFF"
                />
            </g>
            <g fontFamily="Lato-Regular, Lato" fontSize="288">
                <text fill="#c5cee2">
                    <tspan x="11.48" y="284">
                        4
                    </tspan>
                </text>
                <text stroke="#D8D8D8" strokeWidth="1.5" fill="#FFF">
                    <tspan x=".48" y="284">
                        4
                    </tspan>
                </text>
            </g>
            <g fontFamily="Lato-Regular, Lato" fontSize="288">
                <text fill="#DAE7F2" transform="translate(375)">
                    <tspan x="11.48" y="284">
                        3
                    </tspan>
                </text>
                <text stroke="#D8D8D8" strokeWidth="1.5" fill="#FFF" transform="translate(375)">
                    <tspan x=".48" y="284">
                        3
                    </tspan>
                </text>
            </g>
            <path stroke="#D8D8D8" strokeLinecap="square" d="M196 141v142M352 141v142M198 141v98M198 251v32" />
            <circle stroke="#D8D8D8" fill="#F9FAFC" fillRule="nonzero" cx="272.5" cy="156.5" r="4.5" />
            <circle stroke="#D8D8D8" fill="#F9FAFC" fillRule="nonzero" cx="227.5" cy="194.5" r="3.5" />
            <circle stroke="#D8D8D8" fill="#F9FAFC" fillRule="nonzero" cx="219" cy="209" r="6" />
            <circle fill="#D8D8D8" fillRule="nonzero" cx="228" cy="195" r="1" />
            <rect
                stroke="#D8D8D8"
                fill="#F9FAFC"
                fillRule="nonzero"
                x="218.5"
                y="207.5"
                width="19"
                height="3"
                rx="1.5"
            />
            <path
                d="M209.5 146.697v11.942l126 41.669v-10.95l-126-42.66z"
                stroke="#D8D8D8"
                fill="#FFF"
                fillRule="nonzero"
            />
            <path
                d="M209.5 225.399v11.979l126-27.78v-12.971l-126 28.772z"
                stroke="#D8D8D8"
                fill="#FFF"
                fillRule="nonzero"
            />
            <path
                d="M209.5 232.622v11.973l126 26.788v-10.981l-126-27.78z"
                stroke="#D8D8D8"
                fill="#FFF"
                fillRule="nonzero"
            />
            <path
                stroke="#D8D8D8"
                strokeLinecap="square"
                d="M27 284h500M160 294.167h30.5M262 301.167h30.5M207 294.167h174.5"
            />
        </g>
    </svg>
);

export default ForbiddenImage;
