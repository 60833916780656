import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { CheckedCircleIcon, Step, Stepper } from 'onsolve-ui-components';

import './ResetCredentialsStepper.scss';

const ResetCredentialsStepper = ({ currentStep, steps }) => (
    <Stepper activeStep={currentStep} className="onsolve-reset-credentials-stepper">
        {steps.map((step, stepNumber) => {
            return (
                <Step
                    key={step.id}
                    title={<FormattedMessage {...step.title} />}
                    completed={stepNumber < currentStep || (stepNumber === currentStep && step.completed)}
                    disabled={true}
                    completedIcon={<CheckedCircleIcon />}
                    component={step.component}
                />
            );
        })}
    </Stepper>
);

ResetCredentialsStepper.propTypes = {
    currentStep: PropTypes.number,
    steps: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.string, title: PropTypes.string, component: PropTypes.func })
    ),
};

export default ResetCredentialsStepper;
