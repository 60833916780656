import { DEFAULT_LANGUAGE } from '../common/constants/values';
import {
    clientConfigurationService,
    colorConfigurationService,
    localeService,
    locationService,
    settingsConfigurationService,
    templateService,
} from './index';

export const setClientLanguage = async () => {
    let language = DEFAULT_LANGUAGE;
    const uiLangQueryParam =
        locationService.queryReturnUrlParams?.innerParams?.ui_lang ||
        locationService.getQueryParamsFromOrigin()?.ui_lang ||
        locationService.queryStringParams?.ui_lang;

    if (uiLangQueryParam) {
        const localeLang = localeService.getLocaleByCode(uiLangQueryParam);

        if (localeLang) {
            language = localeLang;

            if (!language.translations) {
                language = await localeService.getTranslations(language);
            }
        }
    }

    return language;
};

const configurationService = {
    setupClient: async () => {
        await settingsConfigurationService.set();
        const [client] = await Promise.all([
            clientConfigurationService.getContext(locationService.queryStringParams),
            localeService.getLocales(),
        ]);

        const containClientTemplate = templateService.isSelfRegistrationTemplate(
            client.customizations.templateId,
            client.customizations.brand
        );

        colorConfigurationService.setColorScheme(client.customizations.colorScheme);
        const language = await setClientLanguage();

        return {
            client: client,
            isSelfRegistrationClient: containClientTemplate,
            language,
        };
    },
};

export default configurationService;
