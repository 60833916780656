import { defineMessages } from 'react-intl';

const contactMethodTranslations = defineMessages({
    ng_contactMethod_header: {
        id: 'identityServer.selfRegistration.contactMethod.header',
        defaultMessage: 'Preferred Contact Method',
    },
    ng_contactMethod_label: {
        id: 'identityServer.selfRegistration.contactMethod.label',
        defaultMessage:
            'Select the best method of communication you can be reached at, so we may send you a verification code:',
    },
    ng_contactMethod_selectPlaceholder: {
        id: 'identityServer.selfRegistration.contactMethod.select.placeholder',
        defaultMessage: 'Select primary contact method',
    },
    ng_contactMethod_sendButton: {
        id: 'identityServer.selfRegistration.contactMethod.sendButton',
        defaultMessage: 'Send code',
    },
    ng_contactMethod_textMessage: {
        id: 'identityServer.selfRegistration.contactMethod.textMessage',
        defaultMessage: 'Text message (SMS)',
    },
    ng_contactMethod_voiceCall: {
        id: 'identityServer.selfRegistration.contactMethod.voiceCall',
        defaultMessage: 'Voice call',
    },
    ng_contactMethod_countryCode: {
        id: 'identityServer.selfRegistration.contactMethod.countryCode',
        defaultMessage: 'Country Code',
    },
    ng_contactMethod_countryCode_placeholder: {
        id: 'identityServer.selfRegistration.contactMethod.countryCode.placeholder',
        defaultMessage: 'Select one',
    },
    ng_contactMethod_phoneNumber: {
        id: 'identityServer.selfRegistration.contactMethod.phoneNumber',
        defaultMessage: 'Phone number',
    },
    ng_contactMethod_phoneNumber_placeholder: {
        id: 'identityServer.selfRegistration.contactMethod.phoneNumber.placeholder',
        defaultMessage: 'Enter phone number',
    },
});

export default contactMethodTranslations;
