import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const Page = ({ children, className }) => (
    <div className={classNames('min-h-100 d-flex flex-column', className)}>{children}</div>
);
export const PageContent = ({ children, className }) => (
    <div className={classNames('flex-grow-1', className)}>{children}</div>
);

Page.propTypes = {
    children: PropTypes.object,
    className: PropTypes.object,
};

PageContent.propTypes = {
    children: PropTypes.object,
    className: PropTypes.object,
};
