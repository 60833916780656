import apiService from './apiService';
import settingsConfigurationService from '../settingsConfigurationService';

const mir3Service = {
    async getLocales() {
        const { mir3ApiBaseUrl } = settingsConfigurationService.get();

        return await apiService.get(`${mir3ApiBaseUrl}res/locales.json`);
    },

    async getTranslations(language) {
        const { mir3ApiBaseUrl } = settingsConfigurationService.get();

        return await apiService.get(`${mir3ApiBaseUrl}actions/verbiageLookup/${language.lcId}`);
    },
};

export default mir3Service;
