import { defineMessages } from 'react-intl';

const securityQuestionsTranslations = defineMessages({
    ng_securityQuestions_updateSecurityQuestions: {
        id: 'identityServer.securityQuestions.updateSecurityQuestions',
        defaultMessage: 'Update your security question',
    },
    ng_securityQuestions_description: {
        id: 'identityServer.securityQuestions_description',
        defaultMessage:
            'Choose a security question. This question will help us verify your identity in case you forget your password in the future. Answer is not case-sensitive.',
    },
    ng_securityQuestions_question: {
        id: 'ng_generic_question',
        defaultMessage: 'Question',
    },
    ng_securityQuestions_selectQuestion: {
        id: 'identityServer.securityQuestions.selectQuestion',
        defaultMessage: 'Select question',
    },
    ng_securityQuestions_answer: {
        id: 'identityServer.securityQuestions.answer',
        defaultMessage: 'Answer',
    },
    ng_securityQuestions_enterAnswer: {
        id: 'identityServer.securityQuestions.enterAnswer',
        defaultMessage: 'Enter your answer here',
    },
    ng_securityQuestions_CityBorn: {
        id: 'identityServer.securityQuestions.cityBorn',
        defaultMessage: 'In what city were you born?',
    },
    ng_securityQuestions_FatherYearBorn: {
        id: 'identityServer.securityQuestions.fatherYearBorn',
        defaultMessage: 'In what year was your father born?',
    },
    ng_securityQuestions_MotherYearBorn: {
        id: 'identityServer.securityQuestions.motherYearBorn',
        defaultMessage: 'In what year was your mother born?',
    },
    ng_securityQuestions_FatherMiddleName: {
        id: 'identityServer.securityQuestions.fatherMiddleName',
        defaultMessage: "What is your father's middle name?",
    },
    ng_securityQuestions_MotherMaidenName: {
        id: 'identityServer.securityQuestions.motherMaidenName',
        defaultMessage: "What is your mother's maiden name?",
    },
    ng_securityQuestions_MotherMiddleName: {
        id: 'identityServer.securityQuestions.motherMiddleName',
        defaultMessage: "What is your mother's middle name?",
    },
    ng_securityQuestions_SchoolName: {
        id: 'identityServer.securityQuestions.schoolName',
        defaultMessage: 'What was the name of the first school you attended?',
    },
    ng_securityQuestions_ChildhoodHomeAddress: {
        id: 'identityServer.securityQuestions.childhoodHomeAddress',
        defaultMessage: 'What was the street address of your childhood home?',
    },
    ng_securityQuestions_SpouseMeetPlace: {
        id: 'identityServer.securityQuestions.spouseMeetPlace',
        defaultMessage: 'Where did you meet your spouse?',
    },
    ng_securityQuestions_ChildhoodHero: {
        id: 'identityServer.securityQuestions.childhoodHero',
        defaultMessage: 'What was your childhood hero?',
    },
    ng_securityQuestions_successfullUpdateDescription: {
        id: 'identityServer.securityQuestions.successfullUpdateDescription',
        defaultMessage:
            'Your security question and answers have been updated successfully. Please continue to your homepage.',
    },
    ng_securityQuestions_successfullUpdateMessage: {
        id: 'identityServer.securityQuestions.successfullUpdateMessage',
        defaultMessage: 'Your security question and answers have been updated successfully!',
    },
});

export default securityQuestionsTranslations;
