import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './CustomSignUpPage.scss';

export const LeftSignUp = ({ children }) => (
    <div className="onsolve-custom-sign-up-page__left-area col">{children}</div>
);

LeftSignUp.propTypes = {
    children: PropTypes.node,
};

export const RightSignUp = ({ children }) => (
    <div className="onsolve-custom-sign-up-page__right-area col">{children}</div>
);

RightSignUp.propTypes = {
    children: PropTypes.node,
};

export const CustomSignUpPageBackground = ({ children, className }) => (
    <>
        <CustomSignUpPage className={classNames('onsolve-custom-sign-up-page__background', className)}>
            <LeftSignUp />
            <RightSignUp />
        </CustomSignUpPage>
        {children}
    </>
);

CustomSignUpPageBackground.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export const CustomSignUpPage = ({ children, className }) => (
    <div className={classNames('onsolve-custom-sign-up-page row', className)}>{children}</div>
);

CustomSignUpPage.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};
