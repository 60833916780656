import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import PasswordPopover from './PasswordPopover';
import PasswordTextField from './PasswordTextField';

const PasswordField = ({ passwordValidationMessages = [], ...props }) => {
    const passwordField = useRef(null);
    const [showPopover, setShowPopover] = useState(false);

    return (
        <div
            className="onsolve-input-field__control onsolve-sign-up__form-element"
            onFocus={() => setShowPopover(true)}
            onBlur={() => setShowPopover(false)}
        >
            <div className="onsolve-input-field__group" ref={passwordField}>
                <PasswordTextField {...props} />
            </div>
            {!!passwordValidationMessages.some((x) => !!x) && (
                <PasswordPopover isOpen={showPopover} target={passwordField} value={passwordValidationMessages} />
            )}
        </div>
    );
};

PasswordField.propTypes = {
    isPopoverOpen: PropTypes.bool,
    passwordValidationMessages: PropTypes.arrayOf(PropTypes.object),
};

PasswordField.defaultProps = {
    isPopoverOpen: false,
    passwordValidationMessages: [],
};

export default PasswordField;
