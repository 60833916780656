import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography } from 'onsolve-ui-components';

import { ForbiddenImage, NotFoundImage, UnauthorizedImage, ServerErrorImage } from './Images';

import './style.scss';

const statusCodeMap = {
    '401': UnauthorizedImage,
    '403': ForbiddenImage,
    '404': NotFoundImage,
    '500': ServerErrorImage,
};

const ErrorPage = ({ status, title, description, actions, className }) => {
    const rootClasses = classNames('onsolve-error-page', className);
    const ImageComponent = statusCodeMap[status];

    return (
        <div className={rootClasses}>
            <div className="onsolve-error-page__content">
                <div className="onsolve-error-page__header">
                    <ImageComponent />
                </div>

                <div className="onsolve-error-page__information">
                    {title && (
                        <Typography className="onsolve-error-page__title" variant="p30">
                            {title}
                        </Typography>
                    )}
                    {description && (
                        <Typography className="onsolve-error-page__description" variant="p18" color="secondary">
                            {description}
                        </Typography>
                    )}
                </div>

                {actions && <div className="onsolve-error-page__actions">{actions}</div>}
            </div>
        </div>
    );
};

ErrorPage.propTypes = {
    actions: PropTypes.node,
    className: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    image: PropTypes.node,
    status: PropTypes.oneOf(['401', '403', '404', '500']),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default ErrorPage;
